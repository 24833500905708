@import '../../../global/styles/config';

.gamer-dashboard-page {
  @include flex-column;
  gap: 8px;
  background-color: $background-4;
  position: relative;

  @media (min-width: $screen-lg) {
    background-color: unset;
  }

  &__offers {
    position: relative;
    @include flex-row;
    align-items: center;
    text-align: left;
    border-radius: 8px;
    padding: 8px;
    gap: 16px;
    margin: 24px 16px 0;
    background: $primary-80;

    @media (min-width: $screen-lg) {
      margin-bottom: 24px;
      padding: 16px 24px;
    }

    &_envelop {
      @include flex-row;
      align-items: center;
      width: max-content;
      position: relative;

      &_counter {
        @include flex-row;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 15px;
        height: 15px;
        top: -5px;
        right: -5px;
        background: $opt2-50;
        border-radius: 50%;

        > p {
          @include p-small-2-bold;
          color: $neutral-90;
        }
      }
    }

    &_text {
      > p {
        @include p-2;
        @include text-clamp(1);

        &:first-of-type {
          font-weight: $font-weight-bold;
          color: $primary-40;
        }

        &:last-of-type {
          color: $neutral-30;
        }
      }
    }

    &_tooltip {
      position: absolute;
      @include flex-column;
      background-color: $neutral-80;
      border-radius: 4px;
      padding: 12px;
      gap: 8px;
      top: calc(100% + 16px);
      left: 0;
      z-index: 4;

      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 20px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent $neutral-80 transparent;
      }

      &_heading {
        @include p-1-bold;
      }

      &_text {
        @include p-2;
        color: rgba(212, 212, 216, 1);
      }

      &_close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;

        transition: transform 0.1s;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  &__earnings {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin: 0 16px;
    gap: 8px;

    @media (min-width: $screen-lg) {
      display: none;
    }

    > p {
      @include p-1-bold;
    }

    > div {
      @include flex-row;
      align-items: center;
      gap: 8px;

      > p {
        @include h5;
        color: $primary-40;
      }
    }
  }

  &__games {
    @include flex-column;
    gap: 16px;
    background: $background-5;
    padding: 24px 16px;

    @media (min-width: $screen-lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    > h2 {
      @include p-1-bold;

      @media (min-width: $screen-lg) {
        grid-column: 1 / span 2;
      }
    }

    .loading_fallback_container {
      position: relative;
      background: transparent;
      height: 200px;

      @media (min-width: $screen-lg) {
        grid-column: 1 / span 2;
      }
    }

    &_game {
      @include flex-row;
      align-items: center;
      border-radius: 8px;
      padding: 16px;
      gap: 24px;
      border: 1px solid $background-3;

      > img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 8px;
      }

      > div {
        @include flex-column;
        justify-content: center;
        gap: 10px;

        > h3 {
          @include p-2-bold;
          text-align: left;
        }

        > div {
          @include flex-row;
          gap: 24px;

          &:has(.connected) {
            > p:first-of-type {
              background-color: $neutral-80;
            }
          }

          p {
            @include dots;
            @include p-small-2;

            &:first-of-type {
              padding-left: 4px;
              border-radius: 4px;
              background-color: $primary-80;
            }

            &:last-of-type {
              margin-left: -4px;
            }
          }
        }
      }
    }
  }
}

.cursor-default {
  cursor: default;
}
