@import '../../global/styles/config';

.notifications_container {
  position: relative;
  margin: 0 15px;
  display: flex;
  cursor: pointer;

  .notifications_container__icon_counter {
    @include flex-row;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
    background: $yellow-40;
    border-radius: 50%;
    > p {
      @include p-small-bold;
      color: $neutral-90;
    }
  }

  .notifications_container__dropdown {
    display: none;
    position: absolute;
    width: 340px;
    top: 35px;
    right: -50px;
    background-color: $background-4;
    z-index: 1;

    @media screen and (min-width: $screen-md) {
      top: 25px;
    }

    > h2 {
      @include p-1-bold;
      padding: 10px 15px 15px;
      border-bottom: 1px solid $neutral-90;
      margin: 0 10px;
    }

    .game_authorization_notifications {
      @include flex-column;
      @include center;
      padding: 10px;
      margin: 10px;
      gap: 10px;

      button {
        @include p-2-bold;
        color: $neutral-10;
      }

      &:hover {
        background-color: $neutral-90;
        &:first-of-type {
          border-top-left-radius: $border-radius-sm;
          border-top-right-radius: $border-radius-sm;
        }

        &:last-of-type {
          border-bottom-left-radius: $border-radius-sm;
          border-bottom-right-radius: $border-radius-sm;
        }
      }
    }
  }

  &:hover .notifications_container__dropdown {
    display: block;
  }
}
