@import '../../../global/styles/config';

.leaderboard-modal {
  @include flex-column;
  width: 100%;
  padding: 16px;
  background-color: $background-3;
  border-radius: $border-radius-md;

  .loading_fallback_container {
    position: relative;
    background-color: transparent;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > div {
      > h2 {
        @include h5;

        @media (min-width: $screen-lg) {
          @include h4;
        }
      }

      > p {
        @include p-2;
        color: $neutral-40;
      }
    }
    > button {
      display: flex;
      position: relative;
      top: -10px;
    }
  }

  &__main {
    @include custom-scroll;
    flex: 1;
    overflow: auto;
    margin-top: 15px;
    @include flex-column;
    gap: 20px;

    > div {
      display: grid;
      grid-template-columns: 24px 5fr 1fr;
      align-items: center;
      gap: 10px;

      > div {
        @include flex-row;
        align-items: center;
        gap: 10px;
        > img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: cover;

          @media (min-width: $screen-lg) {
            width: 30px;
            height: 30px;
          }
        }

        > p {
          @include text-clamp(2);
        }
      }

      > p {
        &:first-of-type {
          @include p-2-bold;
          color: $neutral-10;

          @media (min-width: $screen-lg) {
            @include p-1-bold;
          }
        }

        &:last-of-type {
          @include flex-row;
          font-weight: $font-weight-bold;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;

          > img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
