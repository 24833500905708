@import '../../global/styles/config';

.reach-text {
  height: 260px;
  @include flex-column;

  > label {
    @include p-1-bold;
    color: $neutral-20;
  }

  > p {
    margin-top: 1px;
  }

  .quill {
    flex: 1;
    @include flex-column;
    background-color: $neutral-20;
    margin: 10px 0;
    overflow: hidden;

    p {
      color: black;
    }

    .ql-container {
      overflow: hidden;
    }
    // .ql-toolbar {
    //   border: 1px solid $neutral-80;

    //   .ql-formats {
    //     svg {
    //       path {
    //         stroke: $neutral-white;
    //       }
    //     }
    //     span {
    //       &::before {
    //         color: $neutral-white;
    //       }
    //     }

    //     .ql-active {
    //       stroke: red;
    //     }
    //   }
    // }
  }
}
