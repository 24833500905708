@import '../../../global/styles/config';

.gamer-game-page {
  > .loading_fallback_container {
    position: relative;
    height: 100px;
    background-color: transparent;
    align-items: unset;
  }

  &__heading {
    @include flex-column;
    position: relative;
    background-color: $background-4;
    padding: 16px;

    @media (min-width: $screen-lg) {
      height: 400px;
      align-items: flex-end;
      flex-direction: row;
      gap: 24px;
    }

    &_poster {
      width: 100%;
      height: 250px;

      @media (min-width: $screen-lg) {
        position: absolute;
        inset: 0;
        z-index: 0;
        height: unset;
      }
    }

    > img {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      object-fit: cover;
      margin: 0 auto;
      margin-top: -50px;

      @media (min-width: $screen-lg) {
        z-index: 1;
        margin: 0;
        width: 150px;
        height: 150px;
      }
    }

    &_info {
      @media (min-width: $screen-lg) {
        z-index: 1;
        margin: 0;
      }

      &_title {
        @include flex-column;
        align-items: center;
        gap: 8px;
        margin-top: 10px;

        @media (min-width: $screen-lg) {
          align-items: flex-start;
        }

        > h2 {
          @include h6;

          @media (min-width: $screen-lg) {
            @include h4;
          }
        }
        > div {
          &:has(.connected) {
            > p:first-of-type {
              background-color: $neutral-80;
            }
          }

          p {
            @include dots;
            @include p-small-2;
            width: max-content;

            @media (min-width: $screen-lg) {
              @include p-1;
            }

            &:first-of-type {
              padding-left: 4px;
              border-radius: 4px;
              background-color: $primary-80;
            }

            &:last-of-type {
              margin-left: -4px;
            }
          }
        }
      }

      &_store {
        @include flex-row;
        @include center;
        gap: 8px;
        max-width: 230px;
        margin: 16px auto;

        border-radius: 8px;
        border: 1px solid $primary-60;
        padding: 8px;

        @media (min-width: $screen-lg) {
          margin: 16px 0;
        }

        > div {
          > p {
            &:first-of-type {
              font-size: 9px;
            }

            &:last-of-type {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &__connect {
    padding: 24px;
    background-color: $background-4;
    height: 300px;

    @media (min-width: $screen-xl) {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    > h3 {
      @include flex-row;
      align-items: center;
      gap: 8px;

      @media (min-width: $screen-lg) {
        @include h4;
      }
    }

    > div {
      @include flex-column;
      @include center;
      margin-top: 50px;

      > p {
        @include p-small-2;
        color: $neutral-60;
      }
    }
  }
}
