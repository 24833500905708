@import '../../../global/styles/config';

.registration-page {
  @include flex-column;
  gap: 40px;
  padding: 15px;
  background: $background-5;

  &::before {
    @include before-img-background;

    background-image: url('./images/top.webp'), url('./images/bottom.webp');
    background-position: top -30px left -80px, right -300px top 400px;
    background-clip: border-box;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-image: url('./images/top-lg.webp'), url('./images/bottom-lg.webp');
      background-position: top left, right bottom;
    }
  }

  &__logo {
    align-self: center;
    padding-top: 30px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    @include center;
    padding: 0 24px;
    border: 1px solid $neutral-90;
    border-radius: 16px;
    padding: 16px;
    z-index: 1;
    background-color: rgba($color: $background-5, $alpha: 0.7);
    max-width: 450px;
    width: 100%;
    align-self: center;

    @media (min-width: $screen-lg) {
      max-width: 690px;
    }

    .input_container {
      > input {
        height: 56px;
      }
    }

    > h2 {
      @include p-2;
      text-align: center;
      margin: 25px;

      > a {
        color: $opt1-60;
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }
    }

    &_referee {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 4px;
      border-radius: 80px;
      margin: 24px 0 16px 0;
      background: $primary-90;

      > img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100px;
      }
    }

    &_heading {
      margin-bottom: 40px;
      > h1 {
        @include h4;
        text-align: center;
      }
    }

    &_inputs {
      @include flex-column;
      width: 100%;

      &_name {
        @media (min-width: $screen-lg) {
          @include flex-row;
          gap: 16px;
          > div {
            flex: 1;
          }
        }
      }

      > button {
        align-self: center;
        width: 200px;
        height: 56px;
        font-weight: $font-weight-bold;
        margin-top: 40px;
      }

      &_terms {
        @include flex-column;
        margin-top: 10px;
        gap: 20px;
        > div {
          p {
            a {
              color: $opt1-60;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
