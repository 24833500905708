@import '../../../global/styles/config';
.delete_modal {
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;

  &__heading {
    @include flex-row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    > button {
      display: flex;
    }
  }

  > img {
    width: 160px;
    aspect-ratio: 1;
    display: block;
    margin: 0 auto;
  }

  > p {
    text-align: center;
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }
}
