@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Unbounded:wght@200..900&display=swap');
@import './config';

* {
  box-sizing: border-box;
  font-family: $font-family;
}

html {
  width: 100%;
  height: 100%;
}

body {
  @include flex-column;
  @include custom-scroll;
  background-color: $background-5;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02rem;
  overflow: overlay;

  &:has(.modal) {
    overflow: hidden;
  }
}

#root {
  @include flex-column;
  flex: 1;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  color: $neutral-white;
}

a {
  text-decoration: none;
  color: $neutral-white;
}
/* Remove arrows from input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.disabled-text-field {
  > input {
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
  }

  > textarea {
    border: none;
    padding: 0;
    margin: 0;
    font-size: inherit;
  }
}

.navigation_tabs {
  @include flex-row;
  background: $background-5;
  align-items: center;
  height: 80px;

  .active-page {
    > div {
      p {
        @include rainbow-btn-text();
        font-weight: $font-weight-bold;
      }

      svg {
        path {
          stroke: $accent-2;
        }
      }
    }
  }

  > a {
    @include flex-column;
    @include center;
    height: 50px;
    flex: 1;

    div {
      @include flex-column;
      @include center;

      p {
        @include p-small;
        margin-top: 5px;
        color: $neutral-50;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    margin-top: 25px;
    position: static;

    .active-page {
      border-bottom: 1px solid #c1b4fd;

      > div {
        p {
          @include rainbow-btn-text();
        }
      }
    }

    > a {
      border-bottom: 1px solid $neutral-80;

      > div {
        svg {
          display: none;
        }

        p {
          @include p-1-bold;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-xl) {
    padding: 25px 25px 0;
    background-color: rgba($color: $background-5, $alpha: 0.8);
    border-top-left-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;

    > div {
      a {
        p {
          @include p-1;
        }
      }
    }
  }
}
