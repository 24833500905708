@import '../../../global/styles/config';

.registration-gepl-page {
  @include flex-column;
  gap: 40px;
  padding: 15px;
  background: $background-5;

  &::before {
    @include before-img-background;

    background-image: url('./images/top.webp'), url('./images/bottom.webp');
    background-position: top -30px left -80px, right -300px top 400px;
    background-clip: border-box;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-image: url('./images/top-lg.webp'), url('./images/bottom-lg.webp');
      background-position: top left, right bottom;
    }
  }

  &__logo {
    @include flex-row;
    @include center;
    align-self: center;
    gap: 12px;
    padding-top: 30px;
    z-index: 1;

    @media (min-width: $screen-lg) {
      gap: 30px;
    }

    > img:first-of-type {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }

    > img:nth-child(2) {
      width: 30px;
      height: 30px;
    }

    > img:last-of-type {
      @media (min-width: $screen-lg) {
        width: 160px;
        height: 50px;
      }
    }
  }

  &__welcome {
    max-width: 450px;
    margin: 0 auto;

    @media (min-width: $screen-lg) {
      max-width: 690px;
    }
    h1,
    p {
      position: relative;
      text-align: center;
      z-index: 3;
    }

    h1 {
      background: linear-gradient(90deg, #6468d9 -8.12%, #d6d8f5 24.27%, #fff 53.73%, #b7bafb 86.1%, #535af4 107.49%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (min-width: $screen-lg) {
        @include h1;
        margin-bottom: 12px;
      }
    }
  }

  &__form {
    @include flex-column;
    @include center;
    padding: 0 24px;
    border: 1px solid $neutral-90;
    border-radius: 16px;
    padding: 16px;
    z-index: 1;
    background-color: rgba($color: $background-5, $alpha: 0.7);
    max-width: 450px;
    width: 100%;
    align-self: center;

    @media (min-width: $screen-lg) {
      max-width: 690px;
    }

    .input_container {
      > input {
        height: 56px;
      }
    }

    > h2 {
      @include p-2;
      text-align: center;
      margin: 25px;

      > a {
        color: $opt1-60;
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }
    }

    &_referee {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 4px;
      border-radius: 80px;
      margin: 24px 0 16px 0;
      background: $primary-90;

      > img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100px;
      }
    }

    &_heading {
      margin-bottom: 40px;
      > h2 {
        @include p-2;
        text-align: center;

        @media (min-width: $screen-lg) {
          @include p-1;
        }
      }

      > div {
        @include flex-column;
        gap: 16px;
        padding: 12px 0;

        @media (min-width: $screen-lg) {
          flex-direction: row;
          justify-content: center;
        }
        a {
          @include flex-row;
          justify-content: flex-start;
          width: 100%;
          border-radius: 8px;
          padding: 0 8px;
          gap: 8px;
          align-items: center;
          height: 40px;
          color: $neutral-white;
          background-color: #5865f2;

          @media (min-width: $screen-lg) {
            width: 256px;
            height: 56px;
            border-radius: 16px;
            @include p-1;
            text-align: left;

            > img {
              width: 40px;
              height: 40px;
            }
          }

          &:first-of-type {
            --color-bg: #{$background-5};
            background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
              linear-gradient(96.46deg, #ececec 0%, #255cc5 101.5%) border-box;
          }
        }
      }

      > p {
        @include p-small-bold;
        text-align: center;

        @media (min-width: $screen-lg) {
          @include p-1-bold;
        }
      }
    }

    &_inputs {
      @include flex-column;
      width: 100%;

      &_name {
        @media (min-width: $screen-lg) {
          @include flex-row;
          gap: 16px;
          > div {
            flex: 1;
          }
        }
      }

      > button {
        align-self: center;
        width: 200px;
        height: 56px;
        font-weight: $font-weight-bold;
        margin-top: 40px;
      }

      &_terms {
        @include flex-column;
        margin-top: 10px;
        gap: 20px;
        > div {
          p {
            a {
              color: $opt1-60;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
