@import '../../global/styles/config';

.recommendations-gamer {
  border-radius: 16px;
  position: relative;
  margin: 0 16px;
  background: $background-4;

  .carousel {
    overflow: hidden;
    height: 300px;

    @media (min-width: $screen-lg) {
      height: 380px;
    }
  }

  > a {
    @include p-1-bold;
    margin-bottom: 16px;
    display: block;
  }

  > button {
    @include flex-column;
    @include center;
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #5a41d9;
    background: #0e101d;
    z-index: 2;
    &:first-of-type {
      top: calc(50% - 24px);
      left: -10px;
    }
    &:last-of-type {
      top: calc(50% - 24px);
      left: unset;
      right: -10px;
    }
  }

  &__games {
    @include flex-row;
    gap: 16px;
    position: relative;
    width: 100%;

    .loading_fallback_container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &_game {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      padding: 16px;
      gap: 10px;
      box-shadow: 0px 2px 6px 0px rgba(28, 39, 49, 0.08);

      @media (min-width: $screen-lg) {
        height: 380px;
      }

      > img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        grid-row: 1;
        grid-column: 1;

        @media (min-width: $screen-lg) {
          width: 430px;
          height: 320px;
        }
      }

      > p {
        @include p-1-bold;
        bottom: 25px;
        left: 25px;
        grid-row: 2;
        grid-column: 1;
        align-self: flex-end;
      }
    }
  }
}
