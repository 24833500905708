@import '../../../global/styles/config';

.top-up-credit-modal {
  width: 100%;
  padding: 15px;

  @media (min-width: $screen-md) {
    width: 500px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h4-bold;
      color: $content-default;
    }
    > button {
      display: flex;
    }
  }

  &__main {
    &_menu {
      @include flex-row;
      width: 100%;

      > button {
        flex: 1;

        > p {
          padding: 10px;
        }
      }

      &_active {
        border-bottom: 1px solid #c1b4fd;
        > p {
          @include rainbow-btn-text($accent-1);
          @include p-1;
          font-weight: $font-weight-bold;
        }
      }

      &_disabled {
        > p {
          @include p-1;
          color: $neutral-80;
          border-bottom: 1px solid $neutral-80;
        }
      }
    }

    &_actions {
      @include flex-column;

      > h3 {
        @include text-body-semibold;
        color: $content-description;
        margin-bottom: 15px;
      }

      &_preset {
        @include filter-buttons;

        > button {
          flex: 1;
        }
      }

      &_input {
        @include flex-row;
        align-items: center;
        border-bottom: 1px solid $stroke-neutral-subtle;
        margin-bottom: $gap-primary;
        padding-left: $padding-primary;
        > p {
          @include text-body-normal;
          color: $content-description;
        }

        .input_container_new {
          input {
            border-bottom: none;
          }
        }

        &:has(input:focus) {
          outline: none;
          border-radius: $corner-radius-secondary $corner-radius-secondary 0px 0px;
          border-bottom: 1px solid $stroke-neutral-selected;
          background: $fill-neutral-subtle;

          input {
            &:focus {
              outline: none;
              border: none;
              background: unset;
            }
          }
        }
      }

      &_buttons {
        @include flex-row;
        gap: 20px;

        @media (min-width: $screen-md) {
          justify-content: flex-end;
        }

        > button {
          flex: 1;
          height: 40px;

          @media (min-width: $screen-md) {
            flex: unset;
            padding: 0 $padding-quaternary;
          }
        }
      }

      &_error {
        @include error-msg;
        text-align: right;
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  }
}
