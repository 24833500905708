@import '../../global/styles/config';

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 25px;
  z-index: 3;

  > button {
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 30px;
  }

  > [class*='-modal'] {
    @include custom-scroll();
    max-width: 600px;
    max-height: 90dvh;
    background: $background-3;
    border-radius: $border-radius-md;
    padding: 15px;
  }

  > [class*='-modal'].full-size {
    width: 100%;
    height: 100%;
    display: none;

    @media (min-width: $screen-lg) {
      display: grid;
      grid-template-columns: 360px 1fr;
      grid-template-rows: 64px 1fr;
    }

    background: $fill-bg-screen;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 0;

    [class$='__heading'] {
      grid-column: 1 / 3;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      border-bottom: 2px solid $fill-neutral-subtle;
      @include flex-row;

      > div {
        @include flex-row;
        align-items: center;
        gap: 4px;

        svg {
          path {
            stroke: $content-default;
          }
        }
      }

      h1 {
        @include h4;
        color: $content-default;
      }

      svg {
        path {
          stroke: $content-default;
        }
      }
    }

    [class$='__aside'] {
      grid-column: 1 / 2;
      margin: 12px 24px 12px 40px;
      padding: 16px;
      border-radius: $corner-radius-tertiary;
      background: $fill-bg-card;

      [class*='-item'] {
        display: grid;
        grid-template-columns: 48px 1fr;
        grid-template-rows: 48px 1fr;
        gap: 12px;
        align-items: center;
        margin-bottom: 12px;
        background: none;
        border: none;
        cursor: pointer;

        &:last-child {
          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          display: block;
          background: #a7a7af;
          height: 30px;
          width: 1px;
          margin: 0 auto;
        }

        > div {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          background: $fill-neutral-disable;
          @include flex-row;
          @include center;

          > p {
            @include h4-semibold;
            color: $content-description;
          }
        }

        > p {
          @include text-body-normal;
          color: $content-default;
          grid-row: 1 / 2;
          grid-column: 2 / 3;
        }

        &[class*='--active'] {
          > div {
            background: $fill-primary-default;
            border: 3px solid $stroke-primary-subtle;

            > p {
              color: $content-inverse;
            }
          }
          > p {
            font-weight: bold;
          }
        }
      }

      [class*='-item'][class*='--completed'] {
        > div {
          background: $content-default;
        }
      }
    }

    [class$='__main'] {
      @include flex-column;
      grid-column: 2 / 3;
      margin: 12px 40px 12px 0px;
      border-radius: $corner-radius-tertiary;
      background: $fill-bg-card;
      overflow-y: auto;

      > div:first-child {
        flex: 1;
      }
    }

    [class$='__main_footer'] {
      @include flex-row;
      justify-content: flex-end;
      padding: $gap-primary $gap-secondary;
      border-top: 1px solid $stroke-neutral-subtle;
      background: $fill-bg-card;

      > div {
        &:first-child {
          flex: 1;
          > button {
            width: 140px;
            padding: $padding-quinary;
          }
        }
        &:last-child {
          @include flex-row;
          gap: $gap-primary;

          > button {
            width: 140px;
            padding: $padding-quinary;
          }
        }
      }
    }
  }

  //TODO design system change
  &:has(.create-ad-modal),
  &:has(.preview-offer-modal),
  &:has(.create-offer-modal),
  &:has(.top-up-credit-modal),
  &:has(.stripe-modal) {
    > div {
      background: $fill-bg-screen;
    }
  }

  &:has(.stripe-modal) {
    > div {
      padding: 15px 0 15px 15px;
    }
  }
}
