@import '../../../global/styles/config';

.new-organization-modal {
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;
  overflow: auto;

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h6;
    }
    > button {
      display: flex;
    }
  }

  .select_container {
    margin-top: 15px;
    .select {
      &__options {
        > button {
          height: unset;
        }
      }
      &__org-type {
        padding: 8px;

        > p {
          &:last-of-type {
            @include p-small;
            color: $neutral-40;
          }
        }
      }
    }
  }

  &__terms {
    width: 100%;
    > div {
      @include flex-row;
      align-items: center;
      gap: 10px;
      width: 100%;
      > p {
        @include p-2;

        > a {
          margin-left: 5px;
          color: $opt1-60;
        }
      }

      input {
        accent-color: $primary-60;
      }
    }

    > p {
      @include p-small;
      color: $red-60;
      text-align: right;
    }
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 30px;
    > button {
      width: 150px;
      height: 40px;
    }

    @media screen and (min-width: $screen-md) {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }
}
