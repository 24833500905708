@import '../../../global/styles/config';

.organization-settings-page {
  &__banner {
    &_back {
      padding: 15px 15px 0;
      background-color: $background-4;
      margin: 0 -15px;

      > button {
        @include flex-row;
        align-items: center;

        > p {
          @include p-1-bold;
        }
      }

      @media (min-width: $screen-md) {
        background-color: transparent;
        padding: 15px;
      }
    }

    .small-dashboard-bar {
      background-color: $background-4;
      margin: 0 -15px;
      border-radius: unset;
      @media (min-width: $screen-md) {
        display: none;
      }
    }

    &_big {
      display: none;
      gap: 20px;

      @media (min-width: $screen-md) {
        @include flex-row;
      }

      > img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        object-fit: cover;
      }

      > div {
        @include flex-column;
        justify-content: space-around;

        > div {
          @include flex-row;
          gap: 10px;
          p {
            @include p-2;
            color: $neutral-30;

            &:not(:last-of-type) {
              &::after {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                left: 5px;
                width: 5px;
                height: 5px;
                background: $neutral-80;
                border-radius: 50%;
              }
            }
          }
        }
      }
      &_dots {
        @media (min-width: $screen-md) {
          position: relative;
          top: 60px;
        }
        .select {
          border: none;
          background: transparent;

          > button {
            padding: 0;
            > svg {
              display: none;
            }
          }

          .select__options {
            width: 100px;
            top: 30px;
            right: 0;
            left: unset;

            > button {
              @include flex-row;
              align-items: center;
              gap: 5px;
            }
          }
        }
      }
    }
  }

  &__main {
    @media (min-width: $screen-md) {
      @include flex-row;
    }

    .aside-menu__desktop {
      padding-top: 20px;
    }

    &_section {
      flex: 1;

      @media (min-width: $screen-md) {
        padding: 0 15px;
      }
    }
  }

  @media only screen and (min-width: $screen-md) {
    &__main {
      margin-top: 30px;
    }
  }

  @media screen and (min-width: $screen-xl) {
    &__main {
      background-color: rgba($color: $background-5, $alpha: 0.8);
      border-radius: $border-radius-md;
      overflow: hidden;
    }
  }
}
