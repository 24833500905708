@import '../../global/styles/config';

.textarea-container {
  @include flex-column;
  > label {
    @include p-1-bold;
    color: $neutral-20;
    margin-bottom: 5px;
  }

  > textarea {
    width: 100%;
    height: 100px;
    border-radius: $border-radius-sm;
    border: 1px solid $neutral-80;
    padding: 5px 10px;
    background: none;
    color: $neutral-white;
    &:focus {
      outline: none;
    }
    &::placeholder {
      @include p-1;
      color: $neutral-80;
      //TODO ugly fix to vertically center placeholder text (find better solution)
      transform: translateY(2px);
    }

    &:disabled {
      color: $neutral-70;
      background: $neutral-100;
    }
  }

  > div {
    min-height: 16px;
    display: grid;
    grid-template-areas: 'a b';
    > p {
      @include p-small;
      margin-top: 5px;
    }
  }

  &__counter {
    grid-area: a;
    color: $neutral-70;
  }

  &__error {
    grid-area: b;
    justify-self: self-end;
    color: $red-60;
    min-height: 16px;
  }
}

.textarea-container-new {
  @include flex-column;
  > label {
    @include text-body-semibold;
    color: $content-description;
    margin-bottom: $gap-tertiary;
  }

  > textarea {
    @include text-body-medium;
    color: $content-default;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid $stroke-neutral-subtle;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 5px 10px;
    background: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      @include text-body-medium;
      color: $content-subtle;
      //TODO ugly fix to vertically center placeholder text (find better solution)
      transform: translateY(2px);
    }

    &:disabled {
      color: $neutral-70;
      background: $neutral-100;
    }
  }

  > div {
    min-height: 16px;
    display: grid;
    grid-template-areas: 'a b';
    > p {
      @include p-small;
      margin-top: 5px;
    }
  }

  &__counter {
    grid-area: a;
    color: $neutral-70;
  }

  &__error {
    grid-area: b;
    justify-self: self-end;
    color: $red-60;
    min-height: 16px;
  }
}
