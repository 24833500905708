@import '../../../../../global/styles/config';

@mixin key-box {
  > div {
    &:first-of-type {
      @include flex-row;
      gap: 10px;
      > label {
        @include p-1;
        color: $neutral-20;
      }
    }

    &:last-of-type {
      @include flex-row;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      @media (min-width: $screen-lg) {
        margin: 0;
      }

      > p {
        @include p-1-bold;
        word-break: break-all;
      }

      > button {
        @include flex-column;
        @include center;
      }
    }
  }
}

.api-credentials {
  @include flex-column;
  padding: 25px 0;
  gap: 25px;

  @media (min-width: $screen-lg) {
    padding: 0;
  }

  &__heading {
    @include flex-column;
    gap: 25px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    > h2 {
      @include h5;
    }

    > button {
      @include p-1-bold;
      height: 40px;
      width: 100%;
      background-color: $primary-60;

      @media (min-width: $screen-md) {
        width: 250px;
      }
    }
  }

  > h3 {
    @include p-1;
    color: $neutral-10;
    > span {
      font-weight: $font-weight-bold;
      word-break: break-all;
    }
  }

  &__sdk-licence {
    &_header {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      > h2 {
        @include h6;
      }
      > button {
        display: flex;
      }
    }

    &_main {
      > p {
        color: $neutral-40;
      }
      .checkbox-container {
        margin: 24px 0;

        > p {
          flex: 1;
        }
      }
    }

    &_actions {
      --color-bg: #{$background-3};
      @include flex-row;
      @include center;
      gap: 10px;
      padding-top: 15px;
      > button {
        width: 150px;
        height: 40px;
      }

      @media (min-width: $screen-md) {
        justify-content: flex-end;
      }
    }
  }

  &__keys {
    @include flex-column;
    gap: 15px;
    padding: 15px;
    background-color: $background-4;
    &_key,
    &_secret {
      @include key-box;
      @media (min-width: $screen-lg) {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &_key {
      &_expired,
      &_active {
        @include flex-row;
        @include center;
        @include p-small;
        width: 80px;
        color: $neutral-60;
        border: 1px solid $neutral-60;
        border-radius: $border-radius-xsm;
      }

      &_active {
        color: $stroke-positive-default;
        border-color: $stroke-positive-default;
      }
    }

    &_info {
      @media (min-width: $screen-lg) {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
      }
      > div {
        @include p-small;
        @include flex-row;
        gap: 10px;
        flex-wrap: wrap;

        > p {
          color: $neutral-60;
        }
      }

      > button {
        @include flex-row;
        @include center;
        margin-top: 15px;
        height: 30px;
        width: 125px;
        border: 1px solid $primary-60;
        border-radius: $border-radius-sm;

        @media (min-width: $screen-lg) {
          margin: 0;
        }

        > p {
          @include p-2-bold;
        }

        > svg {
          path {
            stroke: $neutral-white;
          }
        }
      }
    }
  }

  &__docs {
    @include p-2;
    color: $neutral-20;
    > a {
      color: $blue-40;
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }
  }
}
