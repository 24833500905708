@import '../../global/styles/config';

.banner-with-dropdown {
  border: 1px solid $gray-300;
  border-radius: 8px;
  background-color: $fill-bg-card;
  padding: $padding-content;
  transition: all 0.3s ease;

  @keyframes loading {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  &.loading {
    animation: loading 1s linear infinite alternate;
  }

  &__header {
    @include flex-row;
    gap: $gap-content;
    align-items: center;
    padding: 16px;
    cursor: pointer;
  }

  &__info {
    @include flex-row;
    align-items: center;
    gap: $gap-secondary;

    > * {
      @include dots;
      @include flex-row;
      align-items: center;

      &::after {
        right: calc($gap-secondary / -2) !important;
        background: $gray-300 !important;
      }
    }
  }

  &__title {
    @include p-1-bold;
    color: $gray-900;
    font-size: 20px;
  }

  &__sub-label {
    @include p-1;
    font-size: 14px;
    color: $gray-600;

    &:last-of-type {
      @include flex-row;
      align-items: center;
      gap: $gap-content;
      > .copy-icon {
        @include flex-row;
        align-items: center;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  &__toggle {
    @include flex-column;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;

    > svg {
      transform: rotate(-90deg);
      transition: transform 0.1s ease;
    }

    &.open {
      > svg {
        transform: rotate(0deg);
      }
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: $gray-900;
      }
    }
  }

  &__content {
    padding: 16px;
    border-top: 1px solid $gray-300;
    background-color: $fill-bg-card;
  }

  &__menu-button {
    margin-left: auto;
    position: relative;

    > svg > path {
      fill: $gray-900;
    }

    .banner-with-dropdown__menu {
      position: absolute;
      right: 0;
      top: 100%;
      min-width: 150px;
      background: $fill-bg-card;
      border: 1px solid $gray-300;
      border-radius: $border-radius-sm;
      z-index: 10;

      button {
        @include p-1;
        padding: $padding-primary;
        width: 100%;
        @include flex-row;
        gap: $gap-content;

        &:hover {
          background: $gray-100;
        }

        &:active {
          background: $gray-200;
        }
      }

      .menu-item {
        @include p-1;
        padding: $padding-primary;
        width: 100%;
        @include flex-row;
        gap: $gap-content;
        cursor: pointer;

        &:hover {
          background: $gray-100;
        }

        &:active {
          background: $gray-200;
        }
      }
    }
  }
}
