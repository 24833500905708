@import '../../../global/styles/config';

.help-page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  background: $background-5;

  > h1 {
    width: 200px;
  }

  @media screen and (min-width: $screen-xl) {
    background: none;
    background-size: cover;
    background-position: center;
    width: 1110px;
    margin: 0 auto;

    &::before {
      @include before-img-background;
      background-image: url('../../../global/images/abstracto.webp');
      background-size: contain;
      background-position: center;
    }
  }
}
