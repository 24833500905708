@import '../../global/styles/config';

.header {
  display: grid;
  grid-template-columns: auto 1fr 150px;
  align-items: center;
  width: 100%;
  position: relative;
  height: 55px;
  padding: 0 15px;
  z-index: 3;

  @media (min-width: $screen-md) {
    gap: 20px;
  }

  @media screen and (min-width: $screen-xl) {
    background-color: transparent;
    height: 65px;
    max-width: $layout-width;
    margin: 0 auto 25px auto;
    padding: 0;
  }

  &__gamer-earnings {
    position: absolute;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin: 0 16px;
    gap: 8px;

    @media (max-width: $screen-md) {
      display: none;
    }

    right: 100px;

    > div {
      @include flex-row;
      align-items: center;
      gap: 8px;
      > p {
        @include h4-semibold;
        color: $content-description;
      }
    }
  }

  &__hamburger_menu {
    @media (min-width: $screen-md) {
      display: none;
    }
    > button {
      @include flex-row;
      width: 40px;
      height: 100%;
    }

    .aside-popup-menu {
      @media (min-width: $screen-md) {
        display: none;
      }

      &__menu {
        z-index: 3;
        > div {
          @include flex-column;
          gap: 30px;
          padding: 0 20px;

          > a {
            @include h4-bold;
          }

          .page-selected {
            &::after {
              top: 9px;
            }
          }
          > div {
            > p {
              @include h5;
              svg {
                display: none;
              }
            }
            > div {
              @include flex-column;
              gap: 10px;
              margin-left: 20px;
              padding: 10px 0;

              > a {
                @include h5;
                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &__rene_logo {
    @include flex-row;
    height: 100%;
    align-items: center;
    cursor: pointer;

    picture {
      @include flex-row;
      align-items: center;
    }

    img {
      width: 150px;
    }
  }

  &__navigation {
    display: none;
    gap: 20px;
    justify-content: flex-start;
    position: relative;

    @media (min-width: $screen-md) {
      @include flex-row;
    }

    @media (min-width: $screen-lg) {
      gap: 40px;
      margin-left: 80px;
    }

    > a,
    p {
      @include text-body-normal;
      color: $neutral-40;
      color: $content-description;
    }

    > div {
      left: 0;
      position: relative;
      cursor: pointer;

      > p {
        @include flex-row;
        align-items: center;
        svg {
          path {
            stroke: $neutral-40;
          }
        }
      }

      &:hover > div {
        @include flex-column;
      }

      > div {
        @include p-2;
        display: none;
        width: 220px;
        right: 0;
        top: 20px;
        gap: 5px;
        background-color: $background-4;
        border-radius: $border-radius-sm;
        border: 1px solid $background-2;
        position: absolute;
        z-index: 1;

        > a {
          @include flex-row;
          @include center;
          height: 40px;
          width: 100%;
          @include text-body-normal;
          color: $neutral-40;

          &::after {
            content: none;
          }

          &:hover {
            color: $primary-10;
            background-color: $neutral-90;
          }
        }
      }
    }
  }

  &__user_actions {
    @include flex-row;
    height: 100%;
    align-items: center;
    justify-self: flex-end;
    &_info {
      position: relative;
      &_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        @media (min-width: $screen-md) {
          width: 40px;
          height: 40px;
        }

        > img {
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }

      &_profile_dropdown {
        @include drop-down(35px, 240px);
        @include drop-down-links;
        z-index: 2;
        right: 0;

        > div:hover {
          cursor: default;

          background-color: initial;

          p {
            color: $neutral-40;
          }
        }

        &_user {
          border-bottom: 1px solid $neutral-90;
          > p {
            @include p-2;
            word-break: break-all;

            &:first-of-type {
              @include h5;
            }
          }
        }

        &_view {
          border-bottom: 1px solid $neutral-90;

          > p:last-of-type {
            @include p-1-bold;
          }
        }

        &_section {
          border-bottom: 1px solid $neutral-90;
        }

        &_logout {
          width: 100%;
          height: 100%;
          text-align: left;
          @include p-1;
        }
      }

      &:hover &_profile_dropdown {
        display: block;
      }
    }
  }
}

.page-selected {
  position: relative;
  font-weight: $font-weight-bold;
  color: $content-primary !important;
}
