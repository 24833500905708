@mixin rainbow-btn-text($color: $accent-2) {
  display: inline-block;
  background: $color;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-config {
  @include flex-row;
  @include center;
  box-sizing: border-box;
  border-radius: $border-radius-sm;
  font-weight: $font-weight-bold;
}

.primary-btn {
  @extend .btn-config;
  background: $accent-3;
  color: $neutral-white;

  &:hover:not([disabled]) {
    background: $primary-90;
    box-shadow: 2px 0px 5px rgba(65, 210, 248, 0.5), -2px 0px 5px rgba(86, 71, 250, 0.5);
  }

  &:active {
    background: $primary-60;
  }

  &[disabled] {
    background: $primary-90;
    color: $primary-80;
  }
}

.secondary-btn {
  @extend .btn-config;
  color: $neutral-white;
  background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box, $accent-2 border-box;
  border: 1px solid transparent;

  &[disabled] {
    background: $primary-90;
    color: $primary-80;
  }
}

.accent-btn {
  @extend .btn-config;
  background-color: $green-60;
  color: $background-4;
}

.tertiary-btn {
  @extend .btn-config;
  @include rainbow-btn-text($accent-1);

  &:hover {
    background: $primary-90;
    box-shadow: 2px 0px 5px rgba(65, 210, 248, 0.5), -2px 0px 5px rgba(86, 71, 250, 0.5);
  }

  &:active {
    background: $primary-90;
    box-shadow: none;
  }
}

.text-btn {
  @include flex-row;
  align-items: center;
  gap: 8px;
  text-decoration: underline;
  color: $primary-50;

  &:hover {
    color: $primary-40;
  }

  &:active {
    color: $primary-60;
  }
}

.large-btn {
  @include h5;
}

.medium-btn {
  @include h6;
}

.small-btn {
  @include p-1;
}

.rainbow-btn-text {
  @include rainbow-btn-text();
}

.center-btn {
  > svg {
    margin-right: 5px;
  }

  > p {
    margin-top: 2px;
  }
}

.tab-btn {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $neutral-60;
  padding: 20px 40px;
  flex-grow: 1;
  box-sizing: border-box;
  border-bottom: 1px transparent;

  transition: border-bottom 0.3s ease, color 0.3s ease;

  @media screen and (max-width: $screen-md) {
    padding: 20px 0;
  }

  &:hover {
    border-bottom: 1px solid $primary-70;
  }

  &_selected {
    @extend .tab-btn;
    color: $primary-50;

    font-weight: 700;
    border-bottom: 1px solid $primary-50;
  }
}

/***************************** New styles *****************************/
.btn-config-new {
  @include flex-row;
  @include center;
  @include label-medium;
  font-weight: 600;
  font-family: $font-family-new;
  box-sizing: border-box;
  border-radius: $corner-radius-primary;
  font-weight: $font-weight-bold;
}

.btn-primary-solid {
  @extend .btn-config-new;
  @include label-medium;
  font-weight: 600;
  background: $fill-primary-default;
  color: $content-inverse;

  &:hover:not([disabled]) {
    background: $fill-primary-hover;
  }

  &:active {
    background: $fill-primary-press;
  }

  &[disabled] {
    background: $fill-neutral-disable;
    color: $content-disable;
  }
}

.btn-primary-ghost {
  @extend .btn-config-new;
  color: $content-primary;
  border: 1px solid $stroke-primary-default;

  &[disabled] {
    color: $content-disable;
  }
}

/*********** Neutral buttons ***********/

.btn-neutral-ghost {
  @extend .btn-config-new;
  border: 1px solid $stroke-neutral-default;
  color: $content-default;
}

.btn-neutral-solid {
  @extend .btn-config-new;
  background: $fill-neutral-default;
  color: $content-inverse;
}

/*********** Negative buttons ***********/

.btn-negative-solid {
  @extend .btn-config-new;
  background: $fill-negative-subtle;
  color: $content-negative;
}

.btn-negative-ghost {
  @extend .btn-config-new;
  border: 1px solid $stroke-negative-default;
  color: $content-negative;
}

.tabs {
  @include flex-row;
  gap: $gap-quaternary;
  border-bottom: 1px solid $content-subtle;
  > button {
    @include text-body-medium;
    color: $content-description;
    flex: 1;
    padding: $padding-quinary 0;
    margin-bottom: -1px;

    &[class^='--selected'] {
      @include text-body-bold;
      color: $content-default;
      border-bottom: 2px solid $stroke-neutral-press;
    }
  }
}
