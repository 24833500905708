@import '../../../../global/styles/config';
.brands {
  &__header {
    margin-top: 20px;
    display: grid;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    @media (min-width: $screen-lg) {
      grid-template-columns: 1fr 1fr 90px;
      grid-template-rows: 1fr;
    }

    > div {
      @include flex-row;
      gap: 5px;

      > button {
        @include flex-row;
        align-items: center;

        @media (min-width: $screen-md) {
          display: none;
        }
      }
    }

    > button {
      width: 80px;
      justify-self: flex-end;
      @media (min-width: $screen-lg) {
        grid-column: 3/4;
      }
    }

    .search-container {
      grid-column: 1 / span 3;

      .search-container__inner {
        width: 100%;
      }

      @media (min-width: $screen-lg) {
        grid-column: 2 / 3;
        grid-row: 1;
      }
    }

    &_back {
      @include flex-row;
      align-items: center;
      @include p-1-bold;
      margin-bottom: 24px;
    }
  }

  &__content {
    margin: 24px 0;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $screen-lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .loading_fallback_container {
      position: relative;
      background-color: transparent;
      height: 200px;
      grid-column: 1 / span 4;
    }
  }

  &__brand {
    width: 100%;
    max-width: 300px;
    padding: 16px;
    @include flex-column;
    border: 1px solid $background-3;
    border-radius: 8px;
    align-items: center;
    justify-self: center;

    > img {
      width: 120px;
      height: 120px;
      border-radius: 8px;
      object-fit: cover;
      margin-bottom: 24px;
    }

    > h3 {
      @include p-2-bold;
      margin-bottom: 8px;
      word-break: break-word;
    }

    > a {
      @include p-small;
      color: $blue-50;
      word-break: break-word;
    }
  }
}

.brand-view {
  margin: 40px 0;

  @media (min-width: $screen-xl) {
    width: 50%;
  }

  .editable_image {
    width: 200px;
    margin-bottom: 24px;

    label {
      @include h6;
      align-self: flex-start;
    }
    &__image {
      width: 180px;
      height: 180px;
    }
  }

  &__website {
    margin-top: 20px;
  }

  &__action {
    width: 150px;
    height: 40px;
  }
}
