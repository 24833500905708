@import '../../global/styles/config';

.radio-buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .radio-button {
    width: auto;
  }

  > label {
    @include p-1-bold;
    color: $neutral-20;
    margin-bottom: 5px;
  }

  &_options {
    display: flex;
    align-items: center;
  }
}

.radio-button {
  width: 26px;
  height: 26px;

  input[type='radio'] {
    display: none;

    &:not(:checked) + label:before {
      border: 2px solid $neutral-60;
      background: $neutral-80;
      animation: ripple 0.2s linear forwards;
    }

    &:not(:checked) + label:after {
      transform: scale(1);
      background: $neutral-80;
    }

    &:checked + label:before {
      border: 2px solid $neutral-40;
      background: unset;
      animation: ripple 0.2s linear forwards;
    }

    &:checked + label:after {
      transform: scale(1);
    }

    &:disabled:checked + label:before {
      border: 2px solid $primary-80;
    }

    &:disabled:checked + label:after {
      background: $neutral-90;
    }

    &:disabled:not(:checked) + label:before {
      border: 2px solid $neutral-80;
      background: $neutral-90;
    }

    &:disabled:not(:checked) + label:after {
      background: $neutral-90;
    }
  }

  label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 3px;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
    }

    &:after {
      top: 6px;
      left: 6px;
      width: 12px;
      height: 12px;
      transform: scale(0);
      background: $primary-60;
    }
  }
}

.radio-buttons-new {
  @include flex-column;
  flex: 1;
  gap: $gap-primary;

  > label {
    @include text-body-semibold;
    color: $content-description;
  }

  .radio-button {
    width: max-content;
    height: 16px;

    input[type='radio'] {
      display: none;

      &:not(:checked) + label:before {
        border: 1px solid $content-default;
        background: none;
        animation: ripple 0.2s linear forwards;
      }

      &:not(:checked) + label:after {
        transform: scale(1);
        background: none;
      }

      &:checked + label {
        font-weight: bold;
      }

      &:checked + label:before {
        border: 2px solid $neutral-40;
        background: unset;
        animation: ripple 0.2s linear forwards;
      }

      &:checked + label:after {
        transform: scale(1);
        border: 4px solid $stroke-primary-default;
      }

      &:disabled:checked + label:before {
        border: 2px solid $primary-80;
      }

      &:disabled:checked + label:after {
        background: $neutral-90;
      }

      &:disabled:not(:checked) + label:before {
        border: 2px solid $neutral-80;
      }

      &:disabled:not(:checked) + label:after {
        background: none;
      }
    }

    label {
      @include text-body-small-normal;
      display: inline-block;
      position: relative;
      padding: 0 30px;
      margin-bottom: 3px;
      cursor: pointer;
      vertical-align: bottom;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &:before {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
      }

      &:after {
        top: 0px;
        left: 0px;
        width: 12px;
        height: 12px;
        transform: scale(0);
        background: none;
      }
    }
  }
}
