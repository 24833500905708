@import '../../global/styles/config';

.small-dashboard-bar {
  cursor: pointer;
  padding: 15px;
  z-index: 1;
  &:hover {
    border: 1px solid $primary-50;

    &::before {
      @include before-img-background;
      z-index: -1;
      background: $primary-90;
      border-radius: $border-radius-sm;
      box-shadow: 0px -8px 24px 0px rgba(66, 0, 253, 0.43) inset;
    }
  }

  @include flex-column;
  justify-content: center;
  gap: 15px;
  position: relative;
  border-radius: $border-radius-sm;
  overflow: hidden;

  &__info {
    @include flex-row;
    gap: 15px;

    video {
      height: 90px;
      width: 90px;
      border-radius: $border-radius-sm;
      object-fit: cover;

      @media (min-width: $screen-xl) {
        height: 100px;
        width: 100px;
      }
    }

    > div {
      @include flex-column;
      justify-content: center;
      &:first-of-type {
        img {
          border-radius: $border-radius-sm;
          height: 40px;
          width: 40px;
          object-fit: cover;

          @media (min-width: $screen-xl) {
            height: 56px;
            width: 56px;
          }
        }
      }

      &:last-of-type {
        h2 {
          @include p-2-bold;
          @include text-clamp(1);

          @media (min-width: $screen-xl) {
            @include p-1-bold;
          }
        }

        h3 {
          @include p-small;
          @include text-clamp(1);

          @media (min-width: $screen-xl) {
            @include p-2;
          }
        }
      }
    }
  }

  &__stats {
    @include flex-row;

    .statistics-container {
      flex: 1;

      > hgroup {
        gap: 5px;
      }
    }
  }

  @media (min-width: $screen-md) {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    height: 120px;
  }
}
