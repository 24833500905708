@import '../../../global/styles/config';

.invite-member-modal {
  width: 100%;

  &__header {
    @include flex-row;
    justify-content: space-between;
    margin-bottom: 20px;
    > h2 {
      @include p-1-bold;
    }
  }
}

.confirm-invite-member {
  @include flex-column;
  gap: 10px;
  .user {
    justify-content: center;
  }

  > p {
    text-align: center;
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }
}

.member-invited {
  width: 100%;
  @include flex-column;
  gap: 20px;
  align-items: center;

  &__header {
    @include flex-row;
    width: 100%;
    justify-content: flex-end;
    > h2 {
      @include p-1-bold;
    }
  }
  > p {
    text-align: center;
    span {
      font-weight: $font-weight-bold;
      margin-right: 5px;
    }
  }

  > button {
    width: 100%;
    height: 40px;
  }
}
