@import '../../../global/styles/config';

.error_message {
  @include flex-column;
  @include center;
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: $border-radius-sm;
  padding: 20px;
  border: 1px solid $red-40;
  max-width: 300px;
  color: $red-40;
  background-color: $background-5;
  animation: movein 0.5s ease forwards, moveout 0.5s 3s ease forwards;
  z-index: 10;

  @keyframes movein {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes moveout {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }
}
