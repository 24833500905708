@import '../../global/styles/config';

.inline-file-upload {
  &__content {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-bottom: $padding-quinary;
    grid-template-rows: 1fr max-content max-content;
    border-bottom: 1px solid $stroke-neutral-subtle;

    &--error {
      border-bottom: 1px solid $content-negative;
    }

    &_input {
      display: none;
      grid-column: 1;
      grid-row: 2;
    }

    label {
      @include text-body-semibold;
      grid-column: 1 / span 2;
      grid-row: 1;
      margin-bottom: $padding-quinary;
    }

    > p {
      @include text-clamp(1);
      @include text-body-medium;
      color: $content-default;
      grid-column: 1;
      grid-row: 2;
      margin-left: $padding-quinary;
      span {
        @include text-body-normal;
        color: $content-subtle;
      }
    }

    > button {
      @include label-x-small;
      align-self: center;
      height: 20px;
      grid-column: 2;
      grid-row: 2;
      padding: 0 $padding-tertiary;
      margin-right: $padding-quinary;
    }
  }

  &__error {
    @include flex-row;
    justify-content: flex-end;
    min-height: 16px;
    gap: $gap-content;
    margin-top: 5px;
    text-align: right;
    grid-column: 1 / span 2;
    grid-row: 3;

    svg {
      path {
        fill: $content-negative;
      }
      width: 16px;
      height: 16px;
    }

    p {
      @include text-caption-normal;
      color: $content-negative;
    }
  }
}
