@import '../../global/styles/config';

.user {
  @include flex-row;
  @include p-1;
  width: 100%;
  align-items: center;
  gap: 10px;
  height: 55px;
  padding: 0 10px;

  &:first-child:hover {
    border-top-right-radius: $border-radius-sm;
    border-top-left-radius: $border-radius-sm;
  }
  &:last-child:hover {
    border-bottom-right-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
  }

  > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  > p {
    @include text-clamp(2);
    text-align: left;
    word-break: break-all;
  }
}

.select {
  cursor: pointer;

  &:hover {
    background-color: $primary-80;
  }
}
