@import '../../../../global/styles/config';
.members {
  padding-bottom: 20px;
  &__heading {
    @include flex-column;
    gap: 20px;
    margin-top: 20px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
    }

    > div {
      @include flex-row;
      align-items: center;
      gap: 5px;

      > button {
        @include flex-row;
        align-items: center;

        @media (min-width: $screen-md) {
          display: none;
        }
      }

      > h2 {
        @include h5;
        width: 100px;

        @media (min-width: $screen-md) {
          @include h4;
        }
      }
    }

    > button {
      @include p-1-bold;
      width: 100%;
      height: 40px;

      @media (min-width: $screen-md) {
        width: 200px;
      }
      > p {
        margin-left: 5px;
      }
    }
  }

  &__main {
    @include flex-column;
    gap: 30px;

    &_owner {
      padding: 30px 0;
      border-bottom: 1px solid $neutral-90;
    }

    .loading_fallback_container {
      position: relative;
      background: transparent;
      height: 200px;
    }

    .member {
      @include flex-row;

      &__owner {
        flex: 1;
        align-self: center;
        text-align: right;
        @include p-small;
        color: $green-50;
      }

      .user {
        @include flex-row;
        width: 60%;
        align-items: center;
        gap: 10px;
        > img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;

          @media (min-width: $screen-lg) {
            width: 50px;
            height: 50px;
          }
        }

        > p {
          @include p-2-bold;

          @media (min-width: $screen-lg) {
            @include p-1-bold;
          }
        }
      }

      &__action {
        @include flex-row;
        justify-content: flex-end;
        align-items: center;
        width: 40%;

        @media (min-width: $screen-lg) {
          gap: 20px;
        }

        > p {
          @include p-small;
          color: $blue-40;
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }

        > button {
          @include flex-row;
          @include center;
          width: 30px;
          height: 30px;
          border: 1px solid $primary-60;
          border-radius: $border-radius-sm;
        }
      }
    }
  }
}
