@import '../../../global/styles/config';

.first-time-user-page {
  @include flex-column;
  gap: 40px;
  padding: 15px;
  background: $background-5;

  &::before {
    @include before-img-background;

    background-image: url('../registration-page/images/top.webp'), url('../registration-page/images/bottom.webp');
    background-position: top -30px left -80px, right -300px top 400px;
    background-clip: border-box;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-image: url('../registration-page/images/top-lg.webp'),
        url('../registration-page/images/bottom-lg.webp');
      background-position: top left, right bottom;
    }
  }

  &__logo {
    align-self: center;
    padding-top: 30px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form,
  &__path {
    padding: 0 24px;
    border: 1px solid $neutral-90;
    border-radius: 16px;
    padding: 16px;
    z-index: 1;
    background-color: rgba($color: $background-5, $alpha: 0.7);
    width: 100%;
    max-width: 450px;
    align-self: center;

    @media (min-width: $screen-lg) {
      max-width: 585px;
    }

    &_heading {
      margin-bottom: 40px;
      > h1 {
        @include h4;
        text-align: center;
      }

      > h2 {
        @include p-1;
        text-align: center;
        margin-top: 15px;

        > a {
          color: $opt1-60;
          font-weight: $font-weight-bold;
        }
      }
    }

    .input_container {
      > input {
        @media (min-width: $screen-lg) {
          height: 56px;
        }
      }
    }

    .textarea-container {
      > textarea {
        height: 200px;
      }
    }

    .select {
      background-color: transparent;

      @media (min-width: $screen-lg) {
        height: 56px;
      }

      &__options {
        > button {
          height: unset;
        }
      }
      &__org-type {
        padding: 8px;

        > p {
          &:last-of-type {
            @include p-small;
            color: $neutral-40;
          }
        }
      }
    }

    .file-upload-container {
      margin-top: 20px;

      &__input {
        width: 200px;
        height: 200px;
      }

      > p {
        text-align: left;
      }
    }

    &_actions {
      --color-bg: #{$background-3};
      @include flex-row;
      @include center;
      gap: 10px;
      padding-top: 30px;
      > button {
        width: 150px;
        height: 40px;

        @media (min-width: $screen-lg) {
          width: 256px;
          height: 56px;
          @include h6;
        }
      }
    }
  }

  &__path {
    > h1 {
      @include h4;
      text-align: center;
    }

    h2,
    ul,
    p {
      @include p-2;
    }

    > h2 {
      margin-top: 8px;
    }

    > ul {
      color: $neutral-white;
    }

    > p {
      a {
        color: $opt1-60;
        text-decoration: underline;
      }
    }

    > div {
      margin-top: 40px;
      @include flex-column;
      align-items: center;
      gap: 24px;
      > button {
        width: 100%;
        height: 40px;

        @media (min-width: $screen-lg) {
          width: 355px;
        }

        &:first-of-type {
          background: $primary-60;
        }

        &:last-of-type {
          background: transparent;
          border: 1px solid $primary-60;
        }
      }
    }
  }
}
