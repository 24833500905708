@import '../../../global/styles/config';

.leave-organization-modal {
  min-height: unset;
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;

  > div:not(:first-child):not(:last-child) {
    @include flex-column;
    margin-bottom: 15px;
  }

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h6;
    }
    > button {
      display: flex;
    }
  }

  &__upload {
    @include flex-column;
    @include center;
    height: 100px;
    border-radius: $border-radius-sm;
    border-color: $neutral-80;
    border-style: dashed;
    position: relative;
    > input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

    > svg {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }

    > p {
      @include p-2;
      color: $neutral-60;
    }
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;

      @media (min-width: $screen-lg) {
        height: 55px;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }

  @media screen and (min-width: $screen-lg) {
    width: 730px;
    &__asset_actions {
      justify-content: flex-end;
    }
  }
}
