@import '../../../global/styles/config';

.admin {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  background-color: $background-5;

  @media only screen and (min-width: $screen-xl) {
    background-color: unset;
    align-items: center;

    &::before {
      @include before-img-background;

      background-image: url('../../../global/images/glow-1.webp'), url('../../../global/images/glow-2.webp');
      background-position: top -200px right -300px, left -200px bottom;
      background-clip: border-box;
    }

    > * {
      width: $layout-width;
    }
  }

  @media (min-width: $screen-xxl) {
    &::before {
      background-position: top -200px right -300px, left -100px bottom;
    }
  }
}

.admin-dashboard {
  &__tabs {
    @include flex-row;
    border-bottom: $primary-70 1px solid;
    margin-bottom: 24px;

    @media (min-width: $screen-md) {
      width: 100%;
    }

    > button {
      @include flex-column;
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: 16px 0;
      font-size: 24px;
      color: $primary-70;
      text-align: center;
      cursor: pointer;

      transition: color 0.1s ease-out, border-color 0.1s ease-out;

      &.active {
        color: $primary-30;
        border-bottom: $primary-30 2px solid;
      }

      @media (min-width: $screen-md) {
        padding: 16px 0;
      }

      &:hover {
        color: $primary-40;
        border-bottom: $primary-40 2px solid;
      }
    }
  }

  &__credit {
    &_org {
      @media (min-width: $screen-md) {
        width: 300px;
      }
    }

    > div {
      @include flex-column;
      margin-top: 16px;
      gap: 24px;

      @media (min-width: $screen-md) {
        flex-direction: row;
      }

      > button {
        height: 40px;
        margin-top: -16px;

        @media (min-width: $screen-md) {
          width: 200px;
          margin-top: 0;
        }
      }
    }
  }

  &__promotions {
    @include flex-column;
    gap: 24px;

    > form {
      @include flex-column;
      gap: 4px;

      > button {
        width: 200px;
        height: 40px;
        font-size: 16px;
        margin-top: 16px;
      }
    }
  }
}
