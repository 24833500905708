@import '../../../global/styles/config';

.gamer-view-page {
  @include flex-column;
  flex: 1;
  background-color: $background-5;
  z-index: 0;

  @media (min-width: $screen-lg) {
    &::before {
      @include before-img-background;

      background-image: url('./images/top.webp'), url('./images/bottom.webp');
      background-position: top -30px right, left bottom;
      background-clip: border-box;

      @media (min-width: $screen-lg) {
        background-image: url('./images/top-lg.webp'), url('./images/bottom-lg.webp');
      }
    }
  }

  @media only screen and (min-width: $screen-xl) {
    background-color: unset;
    align-items: center;

    > * {
      width: $layout-width;
    }
  }
}
