@import '../../../../global/styles/config';

.organization-game-ad-placement {
  display: flex;
  flex-direction: row;

  @media (min-width: $screen-md) {
    margin-top: 24px;
  }

  &__content {
    padding: $padding-content;
    width: 100%;
  }

  @media only screen and (min-width: $screen-xl) {
    padding: 15px 25px;
  }
}
