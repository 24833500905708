$font-weight-bold: 700;
$font-weight-normal: normal;

@mixin big-title-1 {
  font-weight: $font-weight-bold;
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -1px;
}

@mixin big-title-2 {
  font-weight: $font-weight-bold;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -1px;
}

@mixin h1 {
  font-weight: $font-weight-bold;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
}

@mixin h2 {
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -1px;
}
@mixin h3 {
  font-weight: $font-weight-bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}
@mixin h4 {
  font-weight: $font-weight-bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
}
@mixin h5 {
  font-weight: $font-weight-bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.3px;
}
@mixin h6 {
  font-weight: $font-weight-bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

@mixin p-1 {
  font-weight: $font-weight-normal;
  font-size: 16px;
  line-height: 24px;
}

@mixin p-1-bold {
  @include p-1;
  font-weight: $font-weight-bold;
}

@mixin p-2 {
  font-weight: $font-weight-normal;
  font-size: 14px;
  line-height: 20px;
}

@mixin p-2-bold {
  @include p-2;
  font-weight: $font-weight-bold;
}

@mixin p-small {
  font-weight: $font-weight-normal;
  font-size: 12px;
  line-height: 16px;
}

@mixin p-small-bold {
  @include p-small;
  font-weight: $font-weight-bold;
}

@mixin p-small-2 {
  font-weight: $font-weight-normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;
}

@mixin p-small-2-bold {
  @include p-small-2;
  font-weight: $font-weight-bold;
}

@mixin error-msg {
  @include p-small;
  min-height: 16px;
  color: $red-60;
  margin-top: 5px;
  text-align: right;
}

/*************** FONT HEADING ***************/
$font-family-new: 'Barlow', 'sans-serif';
/* H1 variants */
@mixin h1-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -3%;
}

@mixin h1-medium {
  @include h1-normal;
  font-weight: 500;
}

@mixin h1-semibold {
  @include h1-normal;
  font-weight: 600;
}

@mixin h1-bold {
  @include h1-normal;
  font-weight: 700;
}

/* H2 variants */
@mixin h2-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -3%;
}

@mixin h2-medium {
  @include h2-normal;
  font-weight: 500;
}

@mixin h2-semibold {
  @include h2-normal;
  font-weight: 600;
}

@mixin h2-bold {
  @include h2-normal;
  font-weight: 700;
}

/* H3 variants */
@mixin h3-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -3%;
}

@mixin h3-medium {
  @include h3-normal;
  font-weight: 500;
}

@mixin h3-semibold {
  @include h3-normal;
  font-weight: 600;
}

@mixin h3-bold {
  @include h3-normal;
  font-weight: 700;
}

/* H4 variants */
@mixin h4-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -3%;
}

@mixin h4-medium {
  @include h4-normal;
  font-weight: 500;
}

@mixin h4-semibold {
  @include h4-normal;
  font-weight: 600;
}

@mixin h4-bold {
  @include h4-normal;
  font-weight: 700;
}

/*************** FONT TEXT ***************/
/* Text Body variants */
@mixin text-body-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -2%;
}

@mixin text-body-medium {
  @include text-body-normal;
  font-weight: 500;
}

@mixin text-body-semibold {
  @include text-body-normal;
  font-weight: 600;
}

@mixin text-body-bold {
  @include text-body-normal;
  font-weight: 700;
}

/* Text Body Small variants */
@mixin text-body-small-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -2%;
}

@mixin text-body-small-medium {
  @include text-body-small-normal;
  font-weight: 500;
}

@mixin text-body-small-semibold {
  @include text-body-small-normal;
  font-weight: 600;
}

@mixin text-body-small-bold {
  @include text-body-small-normal;
  font-weight: 700;
}

/* Text Caption variants */
@mixin text-caption-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
}

@mixin text-caption-medium {
  @include text-caption-normal;
  font-weight: 500;
}

@mixin text-caption-semibold {
  @include text-caption-normal;
  font-weight: 600;
}

@mixin text-caption-bold {
  @include text-caption-normal;
  font-weight: 700;
}

/* Text Caption Small variants */
@mixin text-caption-small-normal {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0%;
}

@mixin text-caption-small-medium {
  @include text-caption-small-normal;
  font-weight: 500;
}

@mixin text-caption-small-semibold {
  @include text-caption-small-normal;
  font-weight: 600;
}

@mixin text-caption-small-bold {
  @include text-caption-small-normal;
  font-weight: 700;
}

/*************** FONT LABEL ***************/
@mixin label-large {
  font-family: $font-family-new;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -3%;
}

@mixin label-medium {
  @include label-large;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin label-small {
  @include label-large;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -2%;
}
@mixin label-x-small {
  @include label-large;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -2%;
}
