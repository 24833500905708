@import '../../../../global/styles/config';

.credit {
  &__heading {
    margin: 20px 0 20px 0;
    @include flex-row;
    width: 100%;
    justify-content: space-between;
    gap: 5px;

    @media (max-width: $screen-md) {
      align-items: center;
      flex-wrap: wrap;
      row-gap: 24px;

      > h1 {
        width: 90%;
      }
    }

    &__balance {
      @include flex-row;
      align-items: center;
      gap: 24px;

      @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
      }

      &__amount {
        @include flex-row;
        @include p-1;
        align-items: center;
        gap: 12px;

        &__total-balance {
          @include h4;
          color: $primary-40;
        }
      }

      button.primary-btn {
        @include p-1-bold;
        background: $primary-60;
        padding: 8px 32px;
      }

      button.secondary-btn {
        background: $primary-800;
        padding: 8px 32px;

        transition: all 0.1s ease;

        &:hover {
          background: $primary-900;
        }

        &:active {
          background: $primary-950;
        }

        &:disabled {
          cursor: default;
          background: $neutral-600;
        }
      }
    }

    > button {
      @include flex-row;
      align-items: center;

      @media (min-width: $screen-md) {
        display: none;
      }
    }

    > h1 {
      @include h6;

      @media (min-width: $screen-md) {
        @include h4;
      }
    }
  }

  &__main {
    &_actions {
      border-bottom: 1px solid $neutral-90;

      &_tabs {
        display: flex;
        width: 100%;
        align-items: center;
      }

      &_buttons {
        @include flex-column;
        gap: 20px;

        @media (min-width: $screen-md) {
          flex-direction: row;
        }

        @media (min-width: $screen-lg) {
          align-items: center;
        }

        > div {
          width: 100%;
          padding-bottom: 30px;

          &:first-child {
            @include flex-row;
            justify-content: space-between;

            @media (min-width: $screen-md) {
              flex-direction: column;
              justify-content: flex-start;
              gap: 10px;
            }

            > p {
              &:first-child {
                color: $neutral-20;
              }

              &:last-child {
                @include h5;
              }
            }
          }

          &:last-child {
            @include flex-column;
            gap: 20px;

            @media (min-width: $screen-lg) {
              flex-direction: row;
            }

            > button {
              width: 100%;
              height: 40px;

              @media (min-width: $screen-lg) {
                width: 200px;
              }

              &:first-child {
                background: $primary-60;
              }

              &:last-child {
                --color-bg: #{$background-5};
                @include flex-row;
                gap: 5px;
                border: 1px solid $primary-60;

                > svg {
                  > path {
                    stroke: $neutral-white;
                  }
                }
              }
            }
          }
        }
      }

      &_status {
        @include flex-row;
        padding-bottom: 20px;

        gap: 10px;

        &--success {
          > p {
            color: $green-50;
          }
        }

        &--fail {
          > p {
            color: $red-40;
          }
        }
      }
    }

    &_history {
      padding: 15px 0;

      > h2 {
        @include h5;
        margin-bottom: 10px;
      }

      .loading_fallback_container {
        position: relative;
        background: transparent;
        height: 200px;
      }

      .campaigns-list .single-campaign .small-dashboard-bar {
        grid-template-columns: 2fr 3fr;
      }
    }
  }
}
