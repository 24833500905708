@import '../../../global/styles/config';

.transfer-org-ownership-modal {
  @include flex-column;
  width: 350px;

  @media (min-width: $screen-lg) {
    width: 560px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h5;

      @media (min-width: $screen-md) {
        @include h4;
      }
    }
    > button {
      display: flex;
    }
  }

  &__main {
    span {
      @include p-1-bold;
    }
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    flex: 1;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;

      @media (min-width: $screen-lg) {
        width: 160px;
        height: 55px;
      }
    }
  }
}
