@import '../../../../global/styles/config';

.profile-settings {
  &__info,
  &__password {
    @include flex-column;
    padding-bottom: 40px;
    border-bottom: 1px solid $neutral-90;

    form {
      @include flex-column;
    }
    > h3 {
      @include h5;
      width: 65px;
      margin-bottom: 20px;
    }

    button {
      width: 160px;
      height: 40px;
    }
  }

  &__password {
    border-bottom: none;
  }

  &__title {
    @include flex-row;
    align-items: center;
    gap: 10px;
    margin-bottom: 24px;
    > h1 {
      @include h6;

      @media (min-width: $screen-md) {
        @include h4;
      }
    }
    > button {
      @include flex-row;
      align-items: center;
      height: 40px;

      @media (min-width: $screen-md) {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__info {
    &_avatar {
      @include flex-row;
      margin-bottom: 40px;
      .editable_image {
        &__image {
          img {
            border-radius: 50%;
          }
        }
      }
      > div {
        &:last-of-type {
          @include flex-row;
          align-items: center;
          margin-left: 15px;
          > p {
            @include h5;
          }
        }
      }
    }

    &_twitter,
    &_discord {
      @include flex-row;
      align-items: center;
      gap: 20px;

      .input_container {
        flex: 1;
      }

      > button {
        margin-top: 6px;
        height: 40px;
      }
    }
  }

  &__password {
    padding-top: 40px;

    > h3 {
      width: 190px;
    }

    &_submit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > p {
        @include p-2;
        color: $green-60;
        text-align: right;
      }
    }
  }

  &__failed-connection {
    @include error-msg;
    text-align: right;
  }
}
