@import '../../../global/styles/config';
.new-ad-modal {
  width: 100%;
  max-height: 735px;
  overflow: auto;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }
  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h6;

      @media (min-width: $screen-md) {
        @include h4;
      }
    }
    > button {
      display: flex;
    }
  }

  &__reach-text {
    height: 260px;
    @include flex-column;

    > label {
      @include p-1-bold;
      color: $neutral-20;
    }

    > p {
      margin-top: 1px;
    }

    .quill {
      flex: 1;
      @include flex-column;
      background-color: $neutral-20;
      margin: 10px 0;
      overflow: hidden;

      p {
        color: black;
      }

      .ql-container {
        overflow: hidden;
      }
      // .ql-toolbar {
      //   border: 1px solid $neutral-80;

      //   .ql-formats {
      //     svg {
      //       path {
      //         stroke: $neutral-white;
      //       }
      //     }
      //     span {
      //       &::before {
      //         color: $neutral-white;
      //       }
      //     }

      //     .ql-active {
      //       stroke: red;
      //     }
      //   }
      // }
    }
  }

  .search-content-values {
    margin-bottom: 24px;
    margin-top: 0;
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 25px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }

  @media screen and (min-width: $screen-xl) {
    width: 730px;
    &__actions {
      justify-content: flex-end;
    }
  }
}
