@import '../../../global/styles/config';

.organization_dashboard_page {
  &__main {
    @include flex-column;
    gap: 40px;
    margin-top: 50px;
    width: 100%;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }

    > div {
      h2 {
        margin-bottom: 12px;
      }
    }

    .loading_fallback_container {
      position: relative;
      height: 100px;
      background-color: transparent;
    }

    .org-banner__stats {
      .statistics-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 24px;

        @media (min-width: $screen-lg) {
          grid-template-columns: repeat(6, 1fr);
        }

        > hgroup {
          flex: 1;
          width: unset;
        }
      }
    }

    &_content {
      @include flex-column;
      flex: 1;
      gap: 24px;
    }

    &_recently,
    &_feed {
      .loading_fallback_container {
        background: transparent;
        position: relative;
        height: 200px;
      }
    }

    &_recently {
      &_items {
        width: 100%;
        display: grid;
        gap: 16px;

        @media (min-width: $screen-lg) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }
        &_item {
          @include flex-row;
          align-items: center;
          gap: 20px;
          width: 100%;
          height: 120px;
          background-color: $background-4;
          border-radius: $border-radius-sm;
          padding: 16px;

          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: $border-radius-sm;
          }

          p {
            @include text-clamp(3);
          }
        }
      }
    }

    &_feed {
      grid-column: 1 / 2;

      &_items {
        @include flex-column;

        gap: 20px;
        > div {
          display: grid;
          row-gap: 5px;
          border: 1px solid $background-3;
          width: 100%;
          background-color: $background-4;
          border-radius: $border-radius-sm;
          padding: 16px;

          @media (min-width: $screen-lg) {
            grid-template-columns: auto 1fr auto;
            column-gap: 5px;
          }

          a {
            color: $blue-50;
          }

          p:first-of-type {
            display: flex;
            flex: 2;
            @include text-clamp(10);
          }

          p:last-of-type {
            display: flex;
            @include p-small;
            color: $neutral-40;

            @media (min-width: $screen-lg) {
              @include p-1;
            }
          }
        }
      }

      &_no-items {
        @include flex-column;
        gap: 20px;
        width: 100%;

        @media (min-width: $screen-lg) {
          flex-direction: row;
        }

        > button {
          @include flex-column;
          gap: 10px;
          align-items: flex-start;
          background-color: $background-4;
          border-radius: $border-radius-sm;
          border: 1px solid $background-3;
          padding: 16px;

          &:last-of-type {
            svg {
              path {
                stroke: $neutral-white;
              }
            }
          }

          svg {
            path {
              fill: $neutral-white;
            }
          }

          &:hover {
            border: 1px solid $primary-60;
            background-color: $background-3;
          }

          > h2 {
            margin: 0;
            @include p-1-bold;
          }

          > div {
            flex: 1;
            > p {
              text-align: left;
              @include p-small;
              color: $neutral-40;
            }
          }

          > p {
            @include p-1-bold;
            @include flex-row;
            text-align: left;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }

    &_actions {
      display: none;

      @media (min-width: $screen-md) {
        @include flex-column;
        gap: 40px;
        width: 350px;
      }

      &_quick {
        > div {
          @include flex-column;
          gap: 8px;
          padding: 16px;
          background-color: $background-4;
          border-radius: $border-radius-sm;
          > button {
            @include flex-row;
            @include p-1-bold;

            align-items: center;
            gap: 16px;

            height: 50px;
            padding: 8px;

            &:hover {
              border-radius: 8px;
              background: $primary-90;
              box-shadow: -2px 0px 8px 0px #5a41d9, 2px 0px 8px 0px #5a41d9;
            }

            > div {
              @include flex-column;
              align-items: flex-start;
              p {
                color: $neutral-white;

                &:last-of-type {
                  @include p-2;
                  color: $neutral-50;
                }
              }
            }
            svg {
              path {
                fill: $neutral-white;
              }
            }
          }
        }
      }
    }
  }
}
