@import '../../../global/styles/config';

.detailed-resource-view {
  position: absolute;
  @include flex-column;
  @include center;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 3;
  overflow-y: auto;

  > * {
    width: 90%;
  }

  .detailed-resource-img {
    > div {
      @include flex-row;
      width: max-content;
      padding: 8px;
      border-radius: 8px;
      background: $neutral-80;
      position: absolute;
      bottom: -40px;
      transform: translate(-50%, -50%);
      left: 50%;
      gap: 5px;
    }
  }

  &__heading {
    @include flex-row;
    align-items: center;
    justify-content: space-between;

    > h2 {
      @media (min-width: $screen-lg) {
        @include h4;
      }
    }

    > button {
      svg {
        @media (min-width: $screen-lg) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__main {
    @include flex-column;
    align-items: center;
    height: 90%;
    gap: 16px;

    > h3 {
      @include p-1;
      @include flex-column;
      @include center;
      text-align: center;
      margin: 24px 0;

      span:nth-child(2) {
        @include p-1-bold;
      }
    }

    > svg {
      margin-top: 24px;

      @media (min-width: $screen-lg) {
        width: 40px;
        height: 40px;
      }
    }

    > input[type='range'] {
      position: relative;
      z-index: 3;
      width: 70%;
      background: $background-5;
      border-radius: 8px;
      transition: background 0.2s;
      accent-color: $primary-50;
    }

    &_modal {
      @include flex-column;
      @include center;
      position: relative;
      margin: auto;
      border-radius: 8px;
      background: $background-5;
      box-shadow: 2px 0px 16px 0px #5a41d9, -2px 0px 16px 0px #5a41d9;

      .detailed-resource-img {
        @include flex-row;
        justify-content: center;
        align-items: center;
        margin: auto;
        align-self: center;
        justify-self: center;

        > img {
          max-width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
