@import '../../global/styles/config/';

.single-campaign .small-dashboard-bar {
  overflow: visible;
  border-radius: 8px;
  border: 1px solid $background-3;
  background: $background-5;

  @media (min-width: $screen-md) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 72px;
  }

  @media (min-width: $screen-lg) {
    grid-template-columns: 1fr 1fr;
  }

  img {
    display: none;
  }

  &:has(.FINISHED),
  &:has(.STOPPED) {
    .small-dashboard-bar__campaign_time {
      span {
        border-color: $neutral-60;
        color: $neutral-60;
      }
    }
  }

  &:has(.SCHEDULED) {
    .small-dashboard-bar__campaign_time {
      span {
        border-color: $yellow-60;
        color: $yellow-60;
      }
    }
  }

  &:has(.DRAFT) {
    .small-dashboard-bar__campaign_time {
      span {
        border-color: $blue-40;
        color: $blue-40;
      }
    }
  }

  &:has(.IN_PROGRESS) {
    .small-dashboard-bar__campaign_time {
      span {
        border-color: $stroke-positive-default;
        color: $stroke-positive-default;
        // background-color: $fill-positive-subtle;
      }
    }
  }

  @media (min-width: $screen-md) {
    background-color: unset;
    border: 1px solid $background-3;
  }

  &__info {
    flex: 1;
    > div {
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      &:last-of-type {
        h2 {
          position: relative;
          top: 0;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  &__campaign {
    @include flex-column;
    gap: 12px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 2;
      gap: 24px;
    }
    &__clean {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &_time {
      @include p-small;
      @include flex-row;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      color: $neutral-30;

      @media (min-width: $screen-lg) {
        @include p-2;
        gap: 24px;
      }

      span {
        @include p-small-2;
        color: $green-50;
        width: 80px;
        text-align: center;
        border: 1px solid $green-50;
        margin-right: 8px;
        padding: 4px;
        border-radius: $border-radius-xsm;
      }
    }

    &_stats {
      @include flex-row;
      gap: 24px;
      align-items: center;

      @media (min-width: $screen-lg) {
        flex: 1;
      }

      > p {
        @include flex-row;
        @include center;
        @include p-small-bold;
        gap: 4px;
        flex: 1;

        @media (min-width: $screen-lg) {
          @include p-2-bold;
        }
      }

      &--no-ads {
        font-weight: $font-weight-normal !important;
        color: $neutral-50;
      }
    }
  }
}
