@import '../../../global/styles/config';

.game-banner {
  @include flex-column;
  gap: 20px;
  align-items: center;
  position: relative;
  border-radius: $border-radius-sm;
  background-color: $background-4;
  overflow: hidden;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.24));
  z-index: 0;
  margin: 0 -15px;
  padding: 15px 15px;

  @media (min-width: $screen-md) {
    background-color: transparent;
  }

  &__back {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    > a {
      @include flex-row;
      font-weight: $font-weight-bold;
      align-items: center;
      gap: 5px;
    }

    &_dots {
      .select {
        border: none;
        background: transparent;

        > button {
          padding: 0;
          > svg {
            display: none;
          }
        }

        .select__options {
          width: 100px;
          top: 30px;
          right: 0;
          left: unset;

          > button {
            @include flex-row;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  &__main {
    @include flex-row;

    padding: 16px;
    align-items: center;
    gap: 16px;
    width: 100%;

    @media (min-width: $screen-md) {
      border-radius: $border-radius-md;
    }

    &_image {
      height: 65px;
      width: 65px;

      @media (min-width: $screen-md) {
        width: 80px;
        height: 80px;
      }

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    &_info {
      flex: 1;

      @media (min-width: $screen-md) {
        @include flex-column;
      }

      > h1 {
        @include p-1-bold;
        @include text-clamp(1);

        @media (min-width: $screen-md) {
          align-self: baseline;
          @include h4;
        }
      }

      > h2 {
        @include p-small;
        @include text-clamp(1);
        color: $neutral-40;

        @media (min-width: $screen-md) {
          @include p-1;
          align-self: baseline;
        }
      }

      &_stats {
        @include flex-row;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
        align-items: center;
        margin-top: 10px;

        .chain-logo {
          @include flex-row;
          flex-direction: row-reverse;
          width: unset;

          > p {
            @include dots-one;
            color: $neutral-30;
          }
        }

        p {
          @include p-small;
          @include dots;
          color: $neutral-30;
          font-weight: $font-weight-bold;

          @media (min-width: $screen-md) {
            @include p-1;
            font-weight: $font-weight-bold;
          }
        }

        p span {
          font-weight: normal;
        }
      }
    }
  }
}
