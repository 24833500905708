@import '../../../../global/styles/config';

.gamer-offer-page {
  background-color: $background-5;
  padding: 16px;
  @include flex-column;
  align-items: flex-start;
  gap: 24px;

  > a {
    @include flex-row;
    gap: 4px;
    align-items: center;
    width: 100%;
    @include h4;
  }

  &_brand {
    @include flex-row;
    gap: 16px;
    align-items: center;
    width: 100%;

    > img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      border-radius: 50%;
    }

    > h5 {
      @include p-2-bold;
      color: $primary-30;
    }

    > p {
      @include p-2;
      color: $neutral-50;
      margin-left: auto;
    }
  }

  &_content {
    @include flex-column;
    width: 100%;
    gap: 24px;

    > p {
      @include p-2;
      color: $neutral-40;
    }

    > img {
      width: 60%;
      height: auto;
      align-self: center;

      @media screen and (max-width: $screen-md) {
        width: 100%;
      }
    }

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      width: max-content;
      padding: 8px 24px;
      background: $primary-60;
    }
  }
}
