@import '../../../global/styles/config';
.manage-organization-page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  background: $background-5;

  @media screen and (min-width: $screen-xl) {
    background: none;
    background-size: cover;
    background-position: center;
    margin: 0 auto;

    &::before {
      @include before-img-background;
      background-image: url('../../../global/images/abstracto.webp');
      background-size: cover;
      background-position: center;
    }
  }

  &__back {
    display: none;
    @media (min-width: $screen-xl) {
      display: block;
      margin-bottom: 10px;

      > button {
        @include flex-row;
        align-items: center;
        p {
          @include p-2-bold;
          color: $neutral-white;
        }
      }
    }
  }

  &__section {
    width: 100%;

    @media (min-width: $screen-xl) {
      background-color: rgba($color: $background-5, $alpha: 0.8);
      border-radius: $border-radius-md;
      margin-top: 20px;
      padding: 0 25px;
      width: $layout-width;
      background-color: rgba($color: $background-5, $alpha: 0.8);
      flex: 1;
    }

    .loading_fallback_container {
      position: relative;
      height: 200px;
      background: transparent;
    }

    &_heading {
      @include flex-column;
      margin-top: 10px;
      gap: 10px;

      @media screen and (min-width: $screen-md) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
      }

      > h1 {
        @include h5;

        @media (min-width: $screen-md) {
          @include h3;
        }
      }

      > button {
        @include p-1-bold;
        height: 40px;

        @media (min-width: $screen-md) {
          width: 220px;
        }
      }
    }

    &_main {
      width: 100%;
      padding: 25px 0;

      &_empty {
        @include flex-column;
        align-items: center;
        margin-top: 30px;
        > p {
          color: $background-1;
        }
      }

      &_organization {
        @include flex-column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        height: 180px;
        background-color: $background-3;
        border-radius: $border-radius-sm;
        padding: 25px;

        @media (min-width: $screen-md) {
          flex-direction: row;
          height: 165px;
        }

        &_heading {
          @include flex-row;
          align-items: center;
          gap: 10px;
          width: 100%;
          > img {
            width: 75px;
            height: 75px;
            object-fit: cover;

            @media (min-width: $screen-lg) {
              width: 120px;
              height: 120px;
            }
          }

          > div {
            > h2 {
              @include p-1-bold;
              @include text-clamp(3);
              margin-bottom: 5px;
              word-break: break-all;
            }
            > p {
              @include p-2;
              @include text-clamp(3);
              color: $neutral-40;
              word-break: break-all;
            }
          }
        }

        &_actions {
          @include flex-row;
          width: 100%;
          gap: 10px;
          --color-bg: #{$background-3};

          @media (min-width: $screen-md) {
            justify-content: flex-end;
            width: 200px;
          }

          > button {
            flex: 1;
            height: 30px;

            @media (min-width: $screen-md) {
              flex: unset;
              width: 140px;
            }
          }

          .primary-btn {
            background: $primary-60;

            svg {
              path {
                stroke: $neutral-white;
              }
            }
          }

          .secondary-btn {
            background: none;
            border: 2px solid $primary-60;
          }
        }
      }
    }
  }
}
