@import '../../../../../global/styles/config/';

.organization_game_settings_tab__main_general {
  display: grid;

  &_chain {
    .loading_fallback_container {
      position: relative;
      background: transparent;
      height: 50px;
    }
  }

  > div {
    > h3 {
      @include h5;
      margin-bottom: 25px;
    }
  }

  .input_container,
  .textarea_container,
  .file-upload-container {
    > label {
      @include p-1-bold;
    }
  }

  .editable_image {
    justify-self: start;
    margin: 16px 0;

    .editable_image__image {
      width: 150px;
    }
  }

  > button {
    width: 160px;
    height: 40px;
  }

  @media screen and (min-width: $screen-xl) {
    .editable_image {
      .editable_image__image {
        height: 140px;
      }
    }
  }
}
