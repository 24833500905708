@import '../../global/styles/config';

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 4px;
  width: 80px;
  box-sizing: border-box;
  @include p-small;

  &_SUCCESS {
    @extend .status-tag;
    border-color: $green-500;
    color: $green-500;
  }

  &_PENDING {
    @extend .status-tag;
    border-color: $neutral-60;
    color: $neutral-60;
  }

  &_FAILED {
    @extend .status-tag;
    border-color: $red-500;
    color: $red-500;
  }
}
