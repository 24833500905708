@import '../../../../global/styles/config';

.organization-game-players {
  background-color: rgba($color: $background-5, $alpha: 0.8);
  border-bottom-left-radius: $border-radius-md;
  border-bottom-right-radius: $border-radius-md;

  @media (min-width: $screen-xl) {
    margin-top: 24px;
  }

  .loading_fallback_container {
    position: relative;
    height: unset;
    background: unset;
  }

  .search-container {
    padding: 15px 0;
  }

  &__heading {
    margin-bottom: 15px;
    > h2 {
      @include p-1-bold;
    }
  }

  &__main {
    @include flex-column;
    gap: 10px;
  }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 0;
    &__main {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (min-width: $screen-xl) {
    padding: 0 25px 25px;
    &__main {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
