@import '../../../../global/styles/config';

.reach-drawer-content {
  color: white;
  @include flex-column;
  gap: 24px;
  > span {
    font-size: 15px;
  }

  &__input {
    width: 100%;
  }

  &__dates {
    @include flex-row;
    gap: 24px;
    width: 100%;

    .date-picker {
      width: 100%;
    }
  }

  &__disclaimer {
    font-size: 12px;
    color: $neutral-50;
  }
}
