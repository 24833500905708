@import '../../global/styles/config';

.aside-menu {
  @include flex-column;

  &__desktop {
    display: none;
    padding: 10px 0;
    flex: 1;

    > button {
      @include flex-row;
      @include center;
      gap: 10px;
      height: 50px;
      width: 100%;
      border-radius: $border-radius-sm;

      > svg {
        height: 25px;
        width: 25px;
      }

      > p {
        @include p-1;
        color: $neutral-40;
      }
    }

    .active {
      background-color: $primary-60;

      > p {
        @include p-1-bold;
        color: $neutral-white;
      }

      svg {
        path {
          stroke: $neutral-white;
        }
      }
    }

    @media screen and (min-width: $screen-md) {
      @include flex-column;
      width: 260px;
      padding: 0 15px;
      gap: 10px;

      > button {
        height: 35px;
        padding: 0 10px;
        justify-content: flex-start;
        gap: 10px;
      }
    }

    @media only screen and (min-width: $screen-xl) {
      background-color: $background-5;
      padding: 40px 15px;

      > button {
        height: 40px;
      }
    }
  }

  &__mobile {
    margin-top: 20px;
    .select {
      background: $primary-60;

      &__selected {
        color: $neutral-white;
        font-weight: $font-weight-bold;

        > div {
          svg {
            path {
              stroke: $neutral-white;
            }
          }
        }
      }
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &_dropdown {
      @include flex-row;
      gap: 10px;
    }
  }
}
