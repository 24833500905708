@import '../../../global/styles/config';

.organization-view-page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  background-color: $background-5;

  @media only screen and (min-width: $screen-xl) {
    background-color: unset;
    align-items: center;

    &::before {
      @include before-img-background;

      background-image: url('../../../global/images/glow-1.webp'), url('../../../global/images/glow-2.webp');
      background-position: top -200px right -300px, left -200px bottom;
      background-clip: border-box;
    }

    > * {
      width: $layout-width;
    }
  }

  @media (min-width: $screen-xxl) {
    &::before {
      background-position: top -200px right -300px, left -100px bottom;
    }
  }
}
