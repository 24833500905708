@import '../../global/styles/config';
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.slider-track {
  position: relative;
  width: 100%;
  height: 4px;
  background: $primary-80;
  border-radius: 4px;
  margin: 20px 0;
}

.slider-range {
  position: absolute;
  height: 100%;
  background: $primary-60;
  border-radius: 4px;
  height: 6px;
}

.slider-thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--Accent-Purple-Blue-2, linear-gradient(96deg, #826afb 0%, #6ce8f9 101.5%));
  border-radius: 24px;
  cursor: grab;
  top: -7px;
}

.slider-values {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
  font-size: 16px;
}
