@import '../../../global/styles/config';

.organization-campaigns-page {
  @include flex-column;
  flex: 1;
  padding: 24px 0;
  background-color: $background-5;

  @media (min-width: $screen-xl) {
    padding: 24px 16px;
    border-radius: 8px;
  }

  &__heading {
    @include flex-column;
    gap: 16px;

    &_title {
      @include flex-row;
      justify-content: space-between;
      gap: 8px;

      > h1 {
        @include p-1-bold;

        @media (min-width: $screen-md) {
          @include h4;
        }

        span {
          color: $neutral-40;
          font-weight: $font-weight-normal;
        }
      }

      > div {
        @include flex-row;
        gap: 20px;

        > p {
          @include dots;
          @include p-small;
          color: $neutral-30;

          @media (min-width: $screen-md) {
            @include p-2;
          }

          span {
            font-weight: $font-weight-bold;
          }
        }
      }
    }

    &_actions {
      @include flex-column;
      flex-direction: column-reverse;
      gap: 24px;
      margin-bottom: 24px;

      @media (min-width: $screen-md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .search-container {
        @media (min-width: $screen-md) {
          max-width: 410px;
        }
      }

      &_new {
        height: 36px;

        @media (min-width: $screen-md) {
          width: 100px;
        }
      }
    }
  }

  &__content {
    @include flex-column;
    position: relative;

    margin-top: 8px;

    &_campaigns {
      .loading_fallback_container {
        height: 300px;
        top: 70px;
        background-color: transparent;
      }
    }
  }
}
