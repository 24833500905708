@import '../../../global/styles/config';

.modify-ad-modal {
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;
  overflow-y: auto;

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }

  > img {
    display: block;
    margin: 0 auto;
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: $border-radius-md;
    margin-bottom: 15px;
  }

  > video {
    width: 100%;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h6;
    }
    > button {
      display: flex;
    }
  }

  .search-content-values {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .editable_image {
    margin-bottom: 15px;
    width: 240px;
    height: 240px;
    margin: 0 auto;
    &__image {
      border-radius: $border-radius-sm;
      width: 100%;
      height: 100%;
    }

    &__edit {
      width: 50px;
      height: 50px;
      right: -20px;
      > svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__image {
    &__mouseover {
      img {
        cursor: pointer;
        transition: opacity 0.1s ease-out;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &__name {
    margin: 15px 0 10px;
    > div {
      &:first-child {
        @include flex-row;
        align-items: baseline;

        > h2 {
          @include p-1-bold;
        }

        > p {
          margin-left: 16px;
          color: $neutral-30;
          word-break: break-all;
        }
      }
    }

    .input_container {
      margin-top: 10px;
    }
  }
  &__type,
  &__status {
    @include p-1-bold;

    span {
      font-weight: 400;
      margin-left: 20px;
      color: $neutral-30;
    }
  }

  &__type {
    margin-bottom: 10px;
  }

  &__edit_actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 25px;
    > button {
      width: 150px;
      height: 40px;

      &:first-child {
        @include p-1-bold;
        border-color: $red-40;
        border-width: 1px;
        color: $red-40;

        &[disabled] {
          border-color: $neutral-90;
          color: $neutral-90;

          svg {
            path {
              stroke: $neutral-90;
            }
          }
        }

        svg {
          path {
            stroke: $red-40;
          }
        }

        > div {
          @include flex-row;
          align-items: center;
        }
      }

      &:last-child {
        &[disabled] {
          p {
            color: $neutral-90;
          }

          svg {
            path {
              stroke: $neutral-90;
            }
          }
        }
        > div {
          @include flex-row;
          @include p-1-bold;
          align-items: center;

          svg {
            path {
              stroke: $neutral-white;
            }
          }
        }
      }
    }
  }

  &__save_actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 25px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }

  @media screen and (min-width: $screen-xl) {
    width: 730px;
    &__asset_actions {
      justify-content: flex-end;
    }
  }
}
