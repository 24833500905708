@import '../../global/styles/config';
.get-started {
  position: relative;

  @media (min-width: $screen-md) {
    @include p-1-bold;
    position: static;
  }

  &_heading {
    margin-bottom: 12px;
    @include h4;
  }

  &_dropdown {
    @include flex-column;
    gap: 16px;
    background-color: $background-4;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    border-radius: $border-radius-sm;
    padding: 16px;
    cursor: default;

    .step {
      display: grid;
      grid-template-columns: 1fr 20px;
      column-gap: 5px;

      a {
        @include p-2;

        svg {
          margin-left: 5px;
        }
      }

      > svg {
        path {
          stroke: $green-50;
        }
      }

      &__done {
        > a {
          color: $neutral-50;
        }
      }
    }
  }
}
