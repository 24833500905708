@import '../../global/styles/config';
.search-user-dropdown {
  position: relative;
  > h3 {
    @include p-1-bold;
    color: $neutral-20;
    margin-bottom: 10px;
  }

  > p:not(.error-msg) {
    @include p-2;
    color: $neutral-40;
    margin-bottom: 10px;
  }
  .search-container {
    > div {
      flex-basis: 100%;
    }
  }

  &__list {
    position: absolute;
    width: 100%;
    background-color: $background-4;
    margin-top: 10px;
    overflow-y: auto;
    max-height: 290px;
    z-index: 2;

    .lds-ring {
      margin: auto;
      min-height: 55px;
    }

    .user {
      padding-left: 10px;
    }
  }
  .error-msg {
    @include error-msg;
  }
}
