@import '../../global/styles/config';

.date-picker {
  position: relative;

  &__select {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid $neutral-80;
    border-radius: $border-radius-sm;
    > p {
      @include p-1;
      color: $neutral-30;
    }
    > svg {
      path {
        stroke: $neutral-30;
      }
    }
  }

  &__label {
    @include p-1;
    color: $neutral-30;
    margin-bottom: 4px;
    display: block;
  }

  .calendar {
    position: absolute;
  }
}
.calendar {
  border: 1px solid $neutral-80;

  background-color: $background-3;
  width: 100%;
  top: 40px;
  left: 0;

  &__heading {
    @include flex-row;
    justify-content: center;

    gap: 10px;

    > div {
      &:first-child {
        width: 140px;
      }

      &:last-child {
        width: 80px;
      }
    }

    .select_container {
      .select {
        border-radius: unset;
        margin: 16px 0;
        background-color: $neutral-80;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;

    > div {
      height: 35px;
      color: $neutral-white;
      @include flex-row;
      align-items: center;
      justify-content: center;

      > button {
        width: 100%;
        height: 100%;
        time {
          color: $neutral-white;
        }
      }
      .not-current {
        time {
          color: $neutral-80;
        }
      }
    }

    .today {
      border: 1px solid $primary-40;
      border-radius: $border-radius-xsm;
    }

    .selected-day {
      background-color: $primary-60;
      border-radius: $border-radius-xsm;
    }
  }
}
