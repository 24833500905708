@import '../../../global/styles/config';

.organization {
  position: relative;
  cursor: pointer;
  z-index: 2;

  &_image {
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: $screen-md) {
      display: block;
      width: 40px;
      height: 40px;
    }
  }

  &_dropdown {
    @include drop-down(35px, 240px);
    @include drop-down-links;
    right: 0;
  }

  &:hover &_dropdown {
    display: block;
  }
}
