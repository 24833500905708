@import '../../../../../global/styles/config';

.ads-placement {
  @include flex-column;
  overflow-y: auto;
  height: 100%;
  h2 {
    @include h3-semibold;
    color: $content-default;
    margin-bottom: $gap-content;
  }

  h4 {
    @include text-body-normal;
    color: $content-default;
    font-size: 16px;
    font-weight: 400;
  }

  > * {
    padding: $padding-secondary;
  }

  &__content {
    height: 100%;
    overflow-y: auto;
  }

  &__settings {
    @include flex-column;
    margin-top: $gap-secondary;

    > h3 {
      @include h4-semibold;
      color: $content-default;
      margin-bottom: $gap-primary;
    }

    &_iab {
      @include flex-row;
      align-items: center;
      gap: $gap-primary;

      .select_container_new {
        flex: 1;
      }

      &_filters {
        @include flex-row;

        gap: $gap-tertiary;
        > label {
          @include text-body-normal;
          color: $content-default;
        }
      }
    }

    &_genres {
      @include flex-column;
      gap: $gap-primary;
      .radio-buttons-new {
        margin-bottom: $gap-tertiary;
        > label {
          @include text-body-semibold;
          color: $content-description;
        }
      }
    }
  }

  &__specific {
    @include flex-column;
    gap: $gap-secondary;
    margin-top: $gap-secondary;
    padding: $padding-tertiary 0;
    > h3 {
      @include h4-semibold;
      color: $content-default;
    }

    &_content {
      @include flex-row;
      gap: $gap-primary;
      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-neutral-subtle;
      background: $fill-bg-screen;
      padding: $padding-quinary;
      &_left {
        @include flex-column;
        gap: $gap-tertiary;

        > img,
        video {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }

        > p {
          @include text-clamp(1);
          @include text-body-medium;
          color: $content-default;
          text-align: center;
          width: 200px;

          &:last-of-type {
            @include text-body-small-normal;
          }
        }
      }

      &_right {
        @include flex-column;
        justify-content: center;
        gap: $gap-primary;
        flex: 1;

        &_iab {
          @include flex-row;
          align-items: center;
          gap: $gap-primary;

          .select_container_new {
            flex: 1;
          }

          &_filters {
            @include filter-buttons;
            height: 44px;
          }
        }

        &_offer {
          @include flex-row;
          align-items: center;
          justify-content: space-between;
          gap: $gap-primary;

          &_buttons {
            @include flex-row;
            align-items: center;
            gap: $gap-primary;
            > button {
              height: 32px;
              padding: 0 $padding-quaternary;
            }
          }
        }
      }
    }
  }
}
