@import '../../../global/styles/config';

.offer-list {
  width: 100%;
  @include flex-column;
  gap: 16px;
}

.offer-list-item {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 17fr;

  @media (max-width: $screen-md) {
    grid-template-columns: 1fr 5fr;
  }

  padding: 16px;
  background-color: $background-4;
  border-radius: 8px;
  border: 1px solid $background-3;

  transition: background-color 0.2s;

  &.viewed {
    background-color: $background-5;
  }

  &:hover {
    background-color: $background-3;
  }

  &_timestamp {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 14px;
    color: $neutral-50;
  }

  &_image {
    img {
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
  }

  &_content {
    @include flex-column;
    gap: 8px;

    &_company {
      font-size: 18px;
      font-weight: 700;
      color: $primary-30;
    }

    &_description {
      @include flex-column;
      gap: 4px;

      &_title {
        font-size: 14px;
        font-weight: 700;
        color: $neutral-white;
      }

      &_text {
        font-size: 14px;
        color: $neutral-40;

        @media (max-width: $screen-md) {
          @include text-clamp(1);
        }
      }
    }

    &_footer {
      @include flex-row;
      gap: 4px;
      align-items: center;
      @include p-small;
      color: rgba(163, 163, 163, 1);
    }
  }
}
