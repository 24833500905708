@import '../../../../../global/styles/config';

.budget {
  @include flex-column;
  height: 100%;

  h2 {
    @include h3-semibold;
    color: $content-default;
    margin-bottom: $gap-content;
  }

  h3 {
    @include text-body-normal;
    color: $content-default;
    font-size: 16px;
    font-weight: 400;
  }

  &__content {
    padding: $padding-secondary;
    flex: 1;
    overflow-y: auto;
  }

  &__date {
    margin-top: $gap-secondary;
    > h4 {
      @include h4-semibold;
      color: $content-default;
      margin-bottom: $gap-primary;
    }

    &_calendar {
      @include flex-row;
      gap: $gap-primary;

      .input_container_new {
        flex: 1;
      }
    }
  }

  &__stats {
    margin-top: $gap-secondary;
    > h4 {
      @include h4-semibold;
      color: $content-default;
      margin-bottom: $gap-primary;
    }

    > h5 {
      @include text-body-normal;
      color: $content-description;
      margin-bottom: $gap-primary;
    }

    &_table {
      width: 100%;

      table {
        width: 100%;
        border-collapse: collapse;

        .input_container_new {
          input {
            border: none;
          }
        }

        th,
        td {
          padding: $padding-tertiary;
          text-align: left;
          border-bottom: 1px solid $stroke-neutral-subtle;
        }

        th {
          @include text-body-small-bold;
          color: $content-description;
        }

        td {
          @include text-body-small-normal;
          color: $content-default;
        }
      }
    }
  }

  &__total {
    @include flex-row;
    align-items: center;
    gap: $gap-primary;

    > button {
      height: 32px;
      padding: 0px $padding-content;
    }

    > p {
      @include text-body-medium;
      color: $content-default;

      &:last-of-type {
        margin-left: auto;
        span {
          @include text-body-bold;
        }
      }
    }
  }
}
