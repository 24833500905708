@import '../../../global/styles/config';

.create-ad-modal {
  @include flex-column;
  width: 500px;
  height: 680px;

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap-primary;

    > h1 {
      @include h4-semibold;
      color: $content-default;
    }

    > button {
      svg {
        path {
          stroke: $content-default;
        }
      }
    }
  }

  &__ad-type {
    margin-bottom: $gap-secondary;
  }

  &__content {
    @include flex-column;
    flex: 1;
    overflow: hidden;

    .radio-buttons-new {
      margin-bottom: $gap-secondary;
    }
  }

  &__footer {
    @include flex-row;
    justify-content: flex-end;
    gap: $gap-tertiary;
    margin-top: auto;

    > button {
      width: 140px;
      height: 40px;
      padding: 0 $padding-primary;
    }
  }
}

.image-video-ads {
  @include flex-column;
  height: 100%;

  &__description {
    @include flex-row;
    @include text-caption-normal;
    align-items: center;
    gap: $gap-tertiary;
    color: $content-description;
  }

  &__files {
    @include flex-column;
    flex: 1;
    overflow: hidden;
  }

  &__files-list {
    @include flex-column;
    gap: $gap-tertiary;
    margin-top: $gap-secondary;
    overflow-y: auto;

    > div {
      @include flex-row;
      justify-content: space-between;
      align-items: center;

      > div {
        @include flex-row;
        align-items: center;
        gap: $gap-tertiary;

        p {
          @include text-body-small-normal;
          color: $content-description;
        }
      }

      > p {
        @include text-body-small-normal;
        color: $content-description;
      }
    }
  }
}

.branded-object-ads {
  @include flex-column;
  height: 100%;
  &__inputs {
    flex: 1;
  }
}
