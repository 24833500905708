@import '../../../global/styles/config';

.campaign-modal {
  @include custom-scroll;
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;
  overflow-y: auto;

  .modal {
    z-index: 5;
  }

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    > div {
      @include flex-row;
      gap: 6px;
      > h2 {
        @include h4;
      }
    }

    > button {
      display: flex;
    }
  }

  .error-msg {
    margin-top: 5px;
  }

  &__campaign-type {
    @include p-2;
    color: $neutral-40;
    position: relative;
    top: -20px;
  }

  &__type {
    @include flex-column;
    gap: 16px;
    margin-bottom: 16px;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }
    > button {
      width: 100%;
      height: 200px;
      border-radius: 8px;
      border: 1px solid $neutral-80;

      > img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }

      > h3 {
        @include p-1-bold;
      }

      > p {
        @include p-small;
        color: $neutral-40;
      }
    }

    &_set {
      background: linear-gradient(286deg, #1e0e2a 0%, #1f1549 96.35%);
    }
  }

  &__market {
    margin: 24px 0;

    @media (min-width: $screen-md) {
      @include flex-row;
      gap: 24px;
    }

    > div {
      @media (min-width: $screen-md) {
        flex: 1;
      }
    }

    &_type {
      label {
        @include p-1-bold;
        margin: 0 0 15px;
        display: block;
      }

      .checkbox-container {
        @include p-2;
        color: $neutral-30;
      }
    }
  }

  &__date {
    margin: 10px 0;

    > p {
      @include p-small;
      color: $neutral-50;

      @media (min-width: $screen-md) {
        grid-column: 1 / 3;
      }
    }

    @media (min-width: $screen-md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr min-content;
      column-gap: 24px;
    }
  }

  &__demographics {
    margin-bottom: 25px;
    > label {
      @include p-1-bold;
      margin: 0 0 15px;
      display: block;
    }

    &_gender {
      > label {
        @include p-2-bold;
        margin-bottom: 10px;
        display: block;

        @media (min-width: $screen-lg) {
          flex-basis: 20%;
        }
      }

      > div {
        @include flex-row;
        flex-wrap: wrap;
        row-gap: 10px;

        @media (min-width: $screen-lg) {
          flex-basis: 80%;
        }

        > div {
          flex-basis: 50%;
          color: $neutral-30;

          @media (min-width: $screen-md) {
            flex-basis: 30%;
          }
        }
      }
    }
    &_age {
      margin-top: 20px;
    }
  }

  &__impressions {
    &_heading {
      @media (min-width: $screen-md) {
        @include flex-row;
        align-items: center;
        justify-content: space-between;
      }

      label {
        @include p-1-bold;
        margin: 0 0 12px;
        display: block;

        @media (min-width: $screen-md) {
          margin: 0;
        }
      }

      > div {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        > p {
          @include p-small;
          color: $neutral-30;
        }

        > button {
          height: 32px;
          width: 80px;
          background: $primary-60;
          padding: 0 8px;
        }
      }

      &_error {
        color: $red-60 !important;
      }
    }

    &_input {
      @include flex-row;
      align-items: center;
      justify-content: space-between;
      border-radius: $border-radius-sm;
      border: 1px solid $neutral-80;
      margin: 8px 0 16px 0;
      padding: 0 16px 0 8px;

      .input_container {
        > input {
          border: none;
          @include p-1;
          color: $neutral-30;
        }
      }

      &--disabled {
        background: $neutral-100;

        .input_container {
          > input {
            color: $neutral-70;
          }
        }
      }

      &--no-funds {
        border-color: $red-90;
        background: $primary-100;

        .input_container {
          > input {
            color: $red-60;
          }
        }

        .campaign-modal__impressions_input_amount {
          p {
            color: $red-60;
          }
        }
      }

      &_input {
        > p {
          @include p-small;
          color: $neutral-60;
          padding-left: 8px;
          position: relative;
          bottom: 4px;
        }
      }

      &_amount {
        @include flex-row;
        align-items: center;
        gap: 4px;

        p {
          @include p-small;
          color: $neutral-60;
        }
      }
    }

    &_buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 16px;

      @media (min-width: $screen-md) {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      }

      &--selected {
        background-color: $primary-60;
      }

      > button {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid $neutral-60;
        text-align: left;

        > p {
          &:first-of-type {
            @include p-2-bold;
          }
          &:last-of-type {
            @include p-small;
            color: $neutral-30;
          }
        }
      }
    }

    &_des {
      @include p-small;
      margin: 16px 0 24px 0;
      color: $neutral-50;
    }
  }

  &__blockchain {
    margin-top: 16px;
    > select {
      border-radius: $border-radius-sm;
      border: 1px solid $neutral-80;
      padding: 5px 10px;
      background: none;
      color: $neutral-white;
    }

    .loading_fallback_container {
      position: relative;
      background: transparent;
      height: 50px;
    }
  }

  &__asset_actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 560px;
    &__asset_actions {
      justify-content: flex-end;
    }
  }
}
