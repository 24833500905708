@import '../../../global/styles/config';
.new-brand-modal {
  width: 100%;
  padding: 15px;
  background-color: $background-3;
  border-radius: $border-radius-md;
  overflow: auto;

  label {
    color: $neutral-20;
    margin-bottom: 5px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h6;
    }
    > button {
      display: flex;
    }
  }

  .file-upload-container {
    margin-top: 20px;
  }

  &__actions {
    --color-bg: #{$background-3};
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
    &__actions {
      justify-content: flex-end;
    }
  }
}
