@import '../../global/styles/config';

.search-content-values {
  margin-top: 20px;
  &__heading {
    > label {
      @include p-1-bold;
      color: $neutral-20;
      margin-bottom: 5px;
      display: block;
    }
  }

  &__error {
    @include p-small;
    text-align: right;
    margin-top: 5px;
    color: $red-50;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 10px;

    &_value {
      @include flex-row;
      align-items: center;
      padding: 0 5px;
      border: 1px solid $primary-60;
      border-radius: $border-radius-sm;
      min-height: 32px;
      > p {
        @include p-1;
        color: $neutral-20;
        word-break: break-all;
      }

      button {
        @include flex-row;
        align-items: center;
      }
    }

    &_input {
      @include flex-row;
      align-items: center;
      position: relative;
      .search-container__inner_search {
        border: none;
        height: unset;

        svg {
          display: none;
        }
      }

      > button {
        @include flex-row;
        @include p-2-bold;
        align-items: center;
      }

      &_search {
        @include flex-row;
        position: relative;

        .lds-ring div {
          border-color: $neutral-70 transparent transparent;
        }
      }

      &_select {
        @include custom-scroll;
        position: absolute;
        top: 40px;
        border: 1px solid $neutral-80;
        border-radius: $border-radius-sm;
        background: $background-4;
        z-index: 1;
        max-height: 200px;
        overflow-y: scroll;

        > button {
          @include p-2;
          width: 100%;
          min-height: 30px;
          color: $neutral-40;
          text-align: left;
          padding-left: 10px;

          &:hover {
            background-color: $neutral-90;
          }
        }
      }
    }
  }
}
