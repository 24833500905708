@import '../../global/styles/config';

$input-height: 40px;

.input_container {
  @include flex-column;
  position: relative;

  @media screen and (min-width: $screen-sm) {
    &.label_left {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > label {
        flex: 1;
        margin-bottom: 0;
      }
      > input {
        flex: 1;
      }
    }
  }

  > label {
    @include p-1-bold;
    color: $neutral-20;
    margin-bottom: 5px;
  }
  > input {
    width: 100%;
    height: $input-height;
    border-radius: $border-radius-sm;
    border: 1px solid $neutral-80;
    padding: 5px 10px;
    background: none;
    color: $neutral-white;

    &[type='range'] {
      padding: 0px;
      border: none;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      color: $neutral-70;
      background: $neutral-90;
    }

    &::placeholder {
      @include p-1;
      color: $neutral-80;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }

  > p {
    @include p-small;
    min-height: 16px;
    color: $red-60;
    margin-top: 5px;
    text-align: right;
  }

  > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $neutral-70;
  }
}

.input_container_new {
  @include flex-column;
  position: relative;

  @media screen and (min-width: $screen-sm) {
    &.label_left {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > label {
        flex: 1;
        margin-bottom: 0;
      }
      > input {
        flex: 1;
      }
    }
  }

  > label {
    @include text-body-semibold;
    color: $content-description;
    margin-bottom: $gap-tertiary;
  }

  > input {
    @include text-body-medium;
    width: 100%;
    color: $content-default;
    height: $input-height;
    padding: $padding-quinary;
    background: none;
    border: none;
    border-bottom: 1px solid $stroke-neutral-subtle;

    &[type='range'] {
      padding: 0px;
      border: none;
    }

    &:focus {
      outline: none;
      border-radius: $corner-radius-secondary $corner-radius-secondary 0px 0px;
      border-bottom: 2px solid $stroke-neutral-selected;
      background: $fill-neutral-subtle;
    }

    &:disabled {
      border-radius: $corner-radius-secondary $corner-radius-secondary 0px 0px;
      background: $fill-neutral-disable;
      color: $content-disable;
    }

    &::placeholder {
      @include text-body-normal;
      color: $content-subtle;
    }
  }

  > p {
    @include text-caption-normal;
    min-height: 16px;
    color: $content-negative;
    margin-top: 5px;
    text-align: right;
  }

  > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $neutral-70;
  }
}
