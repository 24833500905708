@import '../../../global/styles/config';

.campaign-banner {
  @include flex-column;
  gap: 15px;
  align-items: center;
  position: relative;

  background: $background-4;
  padding: 15px;
  margin: 0 -15px;

  @media (min-width: $screen-md) {
    background: $background-5;
    border-radius: $border-radius-sm;
    margin: 0;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 80px 1fr 30px;
    grid-template-rows: repeat(3, 60px);
    align-items: flex-start;
    column-gap: 15px;
    row-gap: 0;
  }

  &__dots {
    position: absolute;
    top: -40px;
    right: 10px;

    @media (min-width: $screen-md) {
      top: 0;
    }

    @media (min-width: $screen-xl) {
      top: 0;
    }

    .select {
      border: none;
      background: transparent;

      > button {
        padding: 0;
        margin-top: 15px;
        > svg {
          display: none;
        }
      }

      .select__options {
        width: 100px;
        top: 30px;
        right: 0;
        left: unset;

        > button {
          @include flex-row;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  &__title {
    @media (min-width: $screen-md) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    > div {
      @include flex-column;
      gap: 8px;

      h2 {
        @include p-small;
        @include text-clamp(2);
        color: $neutral-20;
        text-align: center;

        @media (min-width: $screen-md) {
          @include p-2;
          text-align: left;
        }
      }
    }

    h1 {
      @include h5;
      @include text-clamp(1);
      text-align: center;
      @media (min-width: $screen-md) {
        text-align: left;
        @include h4;
      }
    }
  }

  &__date {
    @include p-small;
    @include flex-row;
    color: $neutral-50;
    gap: 20px;

    @media (min-width: $screen-md) {
      gap: 10px;
      @include p-2;
    }

    .IN_PROGRESS {
      border-color: $green-60;
      color: $green-60;
    }

    .SCHEDULED {
      border-color: $yellow-60;
      color: $yellow-60;
    }

    .DRAFT {
      border-color: $blue-40;
      color: $blue-40;
    }

    > p {
      color: $neutral-50;
      text-align: center;

      &:first-of-type {
        border: 1px solid;
        border-radius: 4px;
        padding: 0 4px;
      }
      @media (min-width: $screen-md) {
        text-align: left;
      }
    }
  }

  &__stats {
    width: 100%;
    @include flex-row;
    @include center;
    gap: 20px;

    @media (min-width: $screen-md) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      justify-content: flex-start;
      align-self: flex-end;
    }

    > span {
      @include p-small;
      color: $green-50;
      border: 1px solid $green-50;
      padding: 4px;
      border-radius: $border-radius-xsm;
    }

    > p {
      @include dots;
      @include p-small;
      color: $neutral-30;

      &:not(:last-of-type)::after {
        top: -2px;
      }

      @media (min-width: $screen-md) {
        @include p-1;
      }

      span {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__description {
    width: 100%;

    @media (min-width: $screen-md) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      margin-top: 6px;
    }

    > p {
      @include p-small;
      color: $neutral-40;
      word-break: break-all;

      @media (min-width: $screen-md) {
        @include p-2;
      }
    }
  }

  &__action {
    @include p-1-bold;
    @extend .secondary-btn;
    width: 100%;
    height: 40px;
    border-width: 1px;

    @media (min-width: $screen-md) {
      grid-column: 3 / span 4;
      grid-row: 1;
      width: 160px;
      margin-right: 50px;
    }

    &--stop {
      border-color: $red-40;
      color: $red-40;

      &:not(:disabled):hover {
        background: $red-40;
        color: $neutral-white;
      }
      &:disabled {
        border-color: $red-20;
        color: $red-20;
        cursor: default;
      }
    }

    &--schedule {
      border-color: $primary-60;
      color: $primary-60;

      &:not(:disabled):hover {
        background: $primary-60;
        color: $neutral-white;
      }

      &:disabled {
        border-color: $primary-20;
        color: $primary-20;
        cursor: default;
      }
    }

    &--unschedule {
      border-color: $yellow-60;

      &:not(:disabled):hover {
        background: $yellow-60;
        color: $neutral-white;
      }

      &:disabled {
        border-color: $yellow-20;
        color: $yellow-20;
        cursor: default;
      }
    }

    svg {
      path {
        stroke: $neutral-white;
      }
    }
  }
}

.reactivate-campaign-modal {
  width: 100%;

  &__heading {
    @include flex-row;
    justify-content: space-between;

    > h2 {
      @include h5;
    }

    > button {
      @include flex-row;
      align-items: center;
    }
  }

  &__date {
    margin: 40px 0;
    > p:first-of-type {
      @include p-1-bold;
      margin-bottom: 8px;
    }
    &_error {
      @include error-msg();
      height: 16px;
    }
  }

  &__actions {
    @include flex-row;
    gap: 10px;

    @media (min-width: $screen-md) {
      justify-content: flex-end;
    }
    > button {
      --color-bg: #{$background-3};
      flex: 1;
      height: 40px;

      @media (min-width: $screen-md) {
        height: 56px;
        flex: unset;
        width: 150px;
      }
    }
  }
}
