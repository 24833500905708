@import '../../../global/styles/config';

.profile_settings_page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  background: $background-5;

  &__back {
    display: none;
  }

  &__main {
    .aside-popup-menu__menu {
      .aside-menu {
        margin-top: 40px;

        @media (min-width: $screen-md) {
          margin-top: 0;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md) {
    &__main {
      @include flex-row;
      margin-top: 30px;

      &_section {
        flex: 1;
        padding: 0 30px;
      }
    }
  }

  @media screen and (min-width: $screen-xl) {
    background: none;
    background-size: cover;
    background-position: center;

    &::before {
      @include before-img-background;
      background-image: url('../../../global/images/abstracto.webp');
      background-size: cover;
      background-position: center;
    }

    > * {
      width: $layout-width;
      margin: 0 auto;
    }

    &__back {
      display: block;
      margin-bottom: 30px;

      > button {
        @include flex-row;
        align-items: center;
        p {
          @include p-2-bold;
          color: $neutral-white;
        }
      }
    }

    &__main {
      background-color: rgba($color: $background-5, $alpha: 0.8);
      border-radius: $border-radius-md;
      overflow: hidden;
      flex: 1;

      &_section {
        padding: 30px;
      }
    }
  }
}
