@import '../../../global/styles/config';
.verify-user-page {
  @include flex-column;
  flex: 1;
  gap: 20px;
  padding: 15px;
  background: $background-5;
  text-align: center;

  &::before {
    @include before-img-background;

    background-image: url('../registration-page/images/top.webp'), url('../registration-page/images/bottom.webp');
    background-position: top -30px left -80px, right -300px top 400px;
    background-clip: border-box;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-image: url('../registration-page/images/top-lg.webp'),
        url('../registration-page/images/bottom-lg.webp');
      background-position: top left, right bottom;
    }
  }

  &__logo {
    align-self: center;
    padding: 30px 0;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    align-self: center;
    flex: 1;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    margin-bottom: 80px;
    background: rgba($color: $background-5, $alpha: 0.7);
    border-radius: 16px;
    border: 1px solid $neutral-90;

    gap: 20px;
    position: relative;

    @media (min-width: $screen-lg) {
      max-width: 520px;
    }

    > h1 {
      @include h4;
      text-align: center;
    }

    > h2 {
      @include p-2;
      color: $neutral-20;
    }

    > h3 {
      @include p-small-2;
      color: $red-60;
    }

    .otp-group {
      margin-top: 20px;
    }

    > button {
      align-self: center;
      width: 200px;
      height: 40px;
      font-weight: $font-weight-bold;
    }

    &_email {
      @include p-small-2;
      color: $opt1-60;
    }

    &_resend {
      @media (min-width: $screen-md) {
        @include flex-row;
        @include center;
        gap: 5px;
      }
      > p {
        @include p-2;
        color: $neutral-10;
      }

      > button {
        @include p-2-bold;
        color: $primary-40;
      }
    }

    &_back {
      @include p-small;

      > button {
        @include p-small;
        color: $opt1-60;
        margin-left: 5px;
      }
    }
  }
}
