@import '../../global/styles/config';

.game-card {
  width: 280px;
  height: 320px;
  background-color: $fill-bg-card;
  border-radius: $corner-radius-primary;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &__image {
    width: 280px;
    height: 212px;
    object-fit: cover;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    text-align: center;
  }

  &__id {
    font-size: 12px;
    color: $content-description;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $content-default;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: $screen-md) {
    width: 100%;
  }
}
