@import '../../global/styles/config';

.player-card {
  @include flex-column;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $background-3;
  border-radius: $border-radius-sm;

  &__bio {
    @include flex-row;
    align-items: center;
    gap: 10px;
    padding: 0 10px;

    &_img {
      border-radius: 50%;
      overflow: hidden;
      width: 35px;
      height: 35px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    &_name {
      > p {
        @include text-clamp(1);
        flex: 1;
        &:last-of-type {
          @include p-small;
          color: $neutral-60;
        }
      }
    }
  }

  &__social {
    @include flex-row;
    justify-content: flex-end;
    flex: 1;
    margin-top: 10px;

    @media (min-width: $screen-md) {
      flex-direction: column;
    }
    > div {
      width: 50%;
    }

    &_accounts {
      @include flex-row;
      gap: 20px;
      align-items: center;
      padding-left: 10px;
      > a {
        @include flex-row;
        > svg {
          > path {
            fill: $neutral-30;
          }
        }
      }
    }

    .statistics-container {
      @include flex-row;
      justify-content: flex-end;
      padding-right: 15px;
      gap: 20px;

      @media (min-width: $screen-md) {
        flex-direction: column;
        width: 100%;
        gap: 5px;
        margin-top: 10px;
      }

      > hgroup {
        @include flex-column;
        @include center;

        @media (min-width: $screen-md) {
          justify-content: space-between;
          padding-left: 10px;
        }

        @media (min-width: $screen-md) {
          flex-direction: row;
        }
        > h2 {
          @include p-2;
          color: $neutral-20;
        }

        > h3 {
          @include p-2-bold;
        }
      }
    }
  }
}
