@import '../../global/styles/config';
@import '../input/input.scss';

.dropdown-search {
  display: flex;
  flex-direction: column;

  &__input {
    border-radius: 4px;
    font-size: 14px;
  }

  &__options {
    position: absolute;
    top: $input-height;
    &.with-label {
      top: $input-height + 30px;
    }
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    color: $fill-bg-inverse;

    @include custom-scroll;
  }

  &__option {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;

    &:hover,
    &:focus {
      background: $fill-primary-subtle-hover;
    }

    &.selected {
      background: $fill-primary-subtle-selected;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 150px;
      height: 150px;
    }
  }
}
