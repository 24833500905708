@import '../../../global/styles/config';

.organization-game-page {
  @include flex-column;
  flex: 1;
  width: 100%;

  &__navigation {
    @include flex-row;
    align-items: flex-end;
    height: 50px;
    background-color: $background-5;
    border-radius: 16px 16px 0 0;
    margin: 0 -15px;

    @media (min-width: $screen-md) {
      margin: unset;
    }

    > a {
      flex: 1;
      text-align: center;
      padding-bottom: 8px;
      border-bottom: 1px solid $neutral-80;
      color: $neutral-50;

      &:nth-child(2) {
        flex: 1.2;
      }
    }

    .active-page {
      @include rainbow-btn-text();
      font-weight: $font-weight-bold;
      border-bottom: 2px solid $neutral-50;
    }
  }

  &__main {
    background-color: $background-5;
    border-radius: 0 0 16px 16px;

    @media (min-width: $screen-xl) {
      padding: 0 16px 16px;
    }
    .ad-surface-list {
      margin-top: 20px;

      &__heading {
        &_title {
          > button {
            display: none;
          }
        }
      }
    }
  }

  &__collections {
    &__collections__heading {
      @include flex-column;
      flex-direction: column-reverse;

      > button {
        @include p-1-bold;
        width: 230px;
        height: 40px;
        margin: 15px auto;

        > svg {
          margin-right: 5px;
          fill: $neutral-white;
        }

        > p {
          margin-top: 2px;
        }
      }
    }

    &__assets__return {
      @include flex-row;
      > button {
        @include flex-row;
        @include p-1;
        align-items: center;
        color: $neutral-white;
        margin: 30px 0;
      }
    }

    &__assets__tabs {
      @include flex-column;
      align-items: center;
      margin-bottom: 15px;

      > button {
        @include p-1-bold;
        @include flex-row;
        @include center;
        width: 230px;
        height: 40px;
        margin: 25px auto;

        > svg {
          margin-right: 5px;
          fill: $neutral-white;
        }

        > p {
          margin-top: 2px;
        }
      }

      > div {
        @include flex-row;
        width: 100%;
        gap: 20px;

        > * {
          flex-basis: 50%;
          height: 40px;
          border-radius: $border-radius-sm;
          border: 1px solid $neutral-80;
          overflow: hidden;
          padding: 5px;
        }

        > div {
          &:first-of-type {
            @include flex-row;
            @include center;
            input {
              height: 100%;
              width: 100%;
              background: none;
              border: none;
              &::placeholder {
                @include p-1;
                color: $neutral-80;
                // ugly fix to vertically center placeholder text (find better solution)
                transform: translateY(2px);
              }
            }
          }
          &:last-of-type {
            @include flex-row;
            @include center;
            select {
              @include p-1;
              color: $neutral-30;
              width: 100%;
              height: 100%;
              background: none;
              border: none;
            }
          }
        }
      }
    }

    &__assets__list {
      > hgroup {
        @include flex-row;
        margin: 10px 0;

        > h4 {
          @include p-1-bold;
          &:first-of-type {
            border-right: 3px solid $neutral-white;
            padding-right: 10px;
          }
          &:last-of-type {
            padding-left: 10px;
          }
        }
      }

      > div {
        @include flex-row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    &__back {
      display: block;
      margin: 30px 0;
      > a {
        @include flex-row;
        align-items: center;

        p {
          @include p-2-bold;
          color: $neutral-white;
        }
      }
    }

    &__collections {
      &__collections__heading {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 25px;
        > h2 {
          @include h5;
          display: block;
        }

        > button {
          margin: unset;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-xl) {
    &__collections {
      padding: 0 25px 25px;
      background-color: rgba($color: $background-5, $alpha: 0.8);
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;

      &__assets__actions {
        @include flex-row;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        margin-bottom: 0;

        > button {
          margin: 0;
        }

        > div {
          > div {
            &:first-of-type {
              flex-basis: 320px;
            }

            &:last-of-type {
              flex-basis: 160px;
            }
          }
        }
      }

      &__assets__list {
        > hgroup {
          margin: 25px 0;
        }
      }
    }
  }
}
