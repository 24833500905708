/****************/
/* Screen sizes */
/****************/
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1300px;
$screen-xxl: 1500px;

// border
$border-radius-xsm: 4px;
$border-radius-sm: 8px;
$border-radius-md: 16px;
$border-radius-lg: 24px;

// font
$font-family: 'Poppins', 'Barlow', 'sans-serif';

// layout
$layout-width: 1280px;

// dimensions
$dm-2: 2px;
$dm-4: 4px;
$dm-8: 8px;
$dm-12: 12px;
$dm-16: 16px;
$dm-20: 20px;
$dm-24: 24px;
$dm-32: 32px;
$dm-40: 40px;
$dm-48: 48px;
$dm-56: 56px;
$dm-64: 64px;
$dm-72: 72px;
$dm-80: 80px;
$dm-88: 88px;
$dm-96: 96px;
$dm-112: 112px;
$dm-128: 128px;
$dm-144: 144px;
$dm-160: 160px;
$dm-184: 184px;
$dm-200: 200px;
$dm-240: 240px;

// gap
$gap-primary: $dm-16;
$gap-secondary: $dm-24;
$gap-tertiary: $dm-8;
$gap-quaternary: $dm-40;
$gap-content: $dm-4;
$gap-content-small: $dm-2;

// padding
$padding-primary: $dm-16;
$padding-secondary: $dm-24;
$padding-tertiary: $dm-8;
$padding-quaternary: $dm-20;
$padding-quinary: $dm-12;
$padding-content: $dm-4;
$padding-content-small: $dm-2;

// corner radius
$corner-radius-primary: $dm-8;
$corner-radius-secondary: $dm-4;
$corner-radius-tertiary: $dm-16;
$corner-radius-round: $dm-240;
