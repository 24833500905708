@import '../../global/styles/config';

.table-container {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border-left: 1px solid $background-2;
    border-right: 1px solid $background-2;

    th,
    td {
      padding: 10px;
      border: none;
      text-align: left;

      &:not(:first-child) {
        text-align: right;
      }
    }

    th {
      background-color: $background-2;
    }
  }
}

.statistics {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  background-color: $background-2;
  padding: 10px;

  .statistic {
    text-align: center;

    .stat-value {
      font-size: 24px;
      font-weight: $font-weight-bold;
    }

    .stat-name {
      font-size: 14px;
    }
  }
}
