@import '../../../global/styles/config';

.modal {
  > .new-branded-object-modal {
    background: $fill-bg-card;

    > .new-branded-object-modal__heading {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      @include h3-bold;
      padding: $padding-secondary;

      > h2 {
        color: $gray-900;
      }
    }
    form {
      @include flex-column;
      padding: 0 calc(2 * $padding-quaternary) calc(2 * $padding-primary) calc(2 * $padding-quaternary);
      gap: $gap-primary;

      button[type='submit'] {
        height: 40px;
        width: max-content;
        font-weight: 400;
        padding: 0 $padding-secondary;
        margin-left: auto;

        cursor: default;

        &:not(:disabled) {
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
