/**************/
/* Background */
/**************/
$background-1: #484461;
$background-2: #36324d;
$background-3: #27243b;
$background-4: #1b1928;
$background-5: #0e101d;

/***********/
/* Primary */
/***********/
$primary-10: #f3f1fe;
$primary-20: #dfd9fa;
$primary-30: #c6bcf8;
$primary-40: #ab9bfa;
$primary-50: #856efa;
$primary-60: #5a41d9;
$primary-70: #402da1;
$primary-80: #2e2073;
$primary-90: #1f164a;
$primary-100: #120e2a;

/**********/
/* Accent */
/**********/
$accent-1: linear-gradient(96.46deg, #c1b4fd 0%, #b5f4fc 101.5%);
$accent-2: linear-gradient(96.46deg, #826afb 0%, #6ce8f9 101.5%);
$accent-3: linear-gradient(96.46deg, #5838fa 0%, #3ee1f8 101.5%);
$accent-4: linear-gradient(96.46deg, #1c0495 0%, #068293 101.5%);
$accent-5: linear-gradient(96.46deg, #130363 0%, #045762 101.5%);

/***********/
/* Neutral */
/***********/
$neutral-white: #ffffff;
$neutral-10: #f1f0f5;
$neutral-20: #e2e0eb;
$neutral-30: #c6c2d6;
$neutral-40: #a9a3c2;
$neutral-50: #8d85ad;
$neutral-60: #706699;
$neutral-70: #5a527a;
$neutral-80: #433d5c;
$neutral-90: #2d293d;
$neutral-100: #16141e;

/************/
/* Semantic */
/************/

/***** Green ******/
$green-10: #f0fff7;
$green-20: #cdfee3;
$green-30: #9bfdc7;
$green-40: #69fcab;
$green-50: #37fb8f;
$green-60: #0ffa79;
$green-70: #04c85c;
$green-80: #039645;
$green-90: #02642e;
$green-100: #013217;

/***** Blue ******/
$blue-10: #f0f5ff;
$blue-20: #cdddfe;
$blue-30: #9bbcfd;
$blue-40: #699afc;
$blue-50: #3778fb;
$blue-60: #0f5dfa;
$blue-70: #0445c8;
$blue-80: #033496;
$blue-90: #022364;
$blue-100: #011132;

/***** Yellow ******/
$yellow-10: #fffbf0;
$yellow-20: #fef0cd;
$yellow-30: #fde19b;
$yellow-40: #fcd269;
$yellow-50: #fbc337;
$yellow-60: #fab80f;
$yellow-70: #c89004;
$yellow-80: #966c03;
$yellow-90: #644802;
$yellow-100: #322401;

/***** Red ******/
$red-10: #fff0f0;
$red-20: #fecdcd;
$red-30: #fd9b9b;
$red-40: #fc6969;
$red-50: #fb3737;
$red-60: #fa0f0f;
$red-70: #c80404;
$red-80: #960303;
$red-90: #640202;
$red-100: #320101;

/************/
/* Optional */
/************/

/*** Opt 1 ***/
$opt1-10: #f0fffe;
$opt1-20: #d4fffb;
$opt1-30: #b5fff9;
$opt1-40: #96fff6;
$opt1-50: #69fff2;
$opt1-60: #1bffec;
$opt1-70: #00cebc;
$opt1-80: #009387;
$opt1-90: #006058;
$opt1-100: #003833;

/*** Opt 2 ***/
$opt2-10: #fff0fd;
$opt2-20: #ffd4f8;
$opt2-30: #ffb5f3;
$opt2-40: #ff96ed;
$opt2-50: #ff69e6;
$opt2-60: #ff1bd9;
$opt2-70: #ce00ab;
$opt2-80: #93007a;
$opt2-90: #600050;
$opt2-100: #38002f;

/*********************************************/
/* NEW DESIGN SYSTEM */
/*********************************************/

/* GLOBAL COLORS */

/* Gray */
$gray-50: #fafafa;
$gray-100: #ececee;
$gray-200: #dcdce0;
$gray-300: #cacace;
$gray-400: #a7a7af;
$gray-500: #7b7b84;
$gray-600: #505059;
$gray-700: #3a3a41;
$gray-800: #242428;
$gray-900: #14141a;
$gray-950: #0e0e11;

/* Black */
$black-50: rgba(0, 0, 0, 0.05);
$black-100: rgba(0, 0, 0, 0.1);
$black-200: rgba(0, 0, 0, 0.2);
$black-300: rgba(0, 0, 0, 0.3);
$black-400: rgba(0, 0, 0, 0.4);
$black-500: rgba(0, 0, 0, 0.5);
$black-600: rgba(0, 0, 0, 0.6);
$black-700: rgba(0, 0, 0, 0.7);
$black-800: rgba(0, 0, 0, 0.8);
$black-900: rgba(0, 0, 0, 0.9);
$black-950: rgba(0, 0, 0, 0.95);
$black-1: rgba(0, 0, 0, 1);

/* White */
$white-50: rgba(255, 255, 255, 0.05);
$white-100: rgba(255, 255, 255, 0.1);
$white-200: rgba(255, 255, 255, 0.2);
$white-300: rgba(255, 255, 255, 0.3);
$white-400: rgba(255, 255, 255, 0.4);
$white-500: rgba(255, 255, 255, 0.5);
$white-600: rgba(255, 255, 255, 0.6);
$white-700: rgba(255, 255, 255, 0.7);
$white-800: rgba(255, 255, 255, 0.8);
$white-900: rgba(255, 255, 255, 0.9);
$white-950: rgba(255, 255, 255, 0.95);
$white-1: rgba(255, 255, 255, 1);

/* Purple */
$purple-50: #f4f0ff;
$purple-100: #e9e0ff;
$purple-200: #c8b4fe;
$purple-300: #a789fb;
$purple-400: #7f54f8;
$purple-500: #6b38f5;
$purple-600: #5626d9;
$purple-700: #4523a4;
$purple-800: #361d7c;
$purple-900: #27184e;
$purple-950: #1d123a;

/* Coral */
$coral-50: #ffeef1;
$coral-100: #ffdbe2;
$coral-200: #feb4c1;
$coral-300: #fb899e;
$coral-400: #f0607b;
$coral-500: #e93a65;
$coral-600: #c0264c;
$coral-700: #a42343;
$coral-800: #7c1d35;
$coral-900: #4e1822;
$coral-950: #361118;

/* Red */
$red-50: #ffebeb;
$red-100: #ffd8d6;
$red-200: #feb1af;
$red-300: #fb918d;
$red-400: #f2645f;
$red-500: #e03e38;
$red-600: #bf2722;
$red-700: #9e231f;
$red-800: #721b18;
$red-900: #4f1917;
$red-950: #371110;

/* Green */
$green-50: #f1fdf6;
$green-100: #dbfae7;
$green-200: #c7edc5;
$green-300: #9bdd98;
$green-400: #66c563;
$green-500: #45b441;
$green-600: #318b2e;
$green-700: #296e27;
$green-800: #13582d;
$green-900: #113b21;
$green-950: #0b2816;

/* Primary base colors */
$primary-50: $purple-50;
$primary-100: $purple-100;
$primary-200: $purple-200;
$primary-300: $purple-300;
$primary-400: $purple-400;
$primary-500: $purple-500;
$primary-600: $purple-600;
$primary-700: $purple-700;
$primary-800: $purple-800;
$primary-900: $purple-900;
$primary-950: $purple-950;

/* Neutral base colors */
$neutral-white: $white-1;
$neutral-50: $gray-50;
$neutral-100: $gray-100;
$neutral-200: $gray-200;
$neutral-300: $gray-300;
$neutral-400: $gray-400;
$neutral-500: $gray-500;
$neutral-600: $gray-600;
$neutral-700: $gray-700;
$neutral-800: $gray-800;
$neutral-900: $gray-900;
$neutral-950: $gray-950;
$neutral-black: $black-1;

/* Negative */
$negative-50: $red-50;
$negative-100: $red-100;
$negative-500: $red-500;
$negative-800: $red-800;
/* Positive */
$positive-50: $green-50;
$positive-200: $green-200;
$positive-500: $green-500;
/***************************** LIGHT THEME *****************************/

/*************** FILL ***************/
/* Background */
$fill-bg-screen: $neutral-50;
$fill-bg-inverse: $neutral-black;
$fill-bg-card: $neutral-white;
$fill-bg-disable: $neutral-300;
/* Primary */
$fill-primary-default: $primary-500;
$fill-primary-hover: $primary-400;
$fill-primary-press: $primary-600;
$fill-primary-selected: $primary-500;
$fill-primary-intense: $primary-800;
$fill-primary-subtle: $primary-50;
$fill-primary-subtle-hover: $primary-100;
$fill-primary-subtle-press: $primary-200;
$fill-primary-subtle-selected: $primary-500;
/* Neutral */
$fill-neutral-default: $neutral-950;
$fill-neutral-hover: $neutral-800;
$fill-neutral-press: $neutral-700;
$fill-neutral-selected: $neutral-950;
$fill-neutral-disable: $neutral-200;
$fill-neutral-subtle: $black-50;
$fill-neutral-subtle-hover: $black-100;
$fill-neutral-subtle-press: $black-200;
$fill-neutral-subtle-selected: $neutral-950;
$fill-neutral-inverse: $neutral-50;
$fill-neutral-inverse-hover: $neutral-200;
$fill-neutral-inverse-press: $neutral-300;
$fill-neutral-inverse-selected: $neutral-50;
$fill-neutral-inverse-disable: $neutral-800;

/* Positive */
$fill-positive-intense: $positive-200;
$fill-positive-subtle: $positive-50;

/* Negative */
$fill-negative-subtle-hover: $negative-100;
$fill-negative-intense: $negative-800;
$fill-negative-subtle: $negative-50;
/*************** CONTENT ***************/
$content-default: $neutral-900;
$content-inverse: $neutral-50;
$content-description: $neutral-600;
$content-disable: $neutral-400;
$content-subtle: $neutral-300;
$content-negative: $negative-500;
$content-primary: $primary-500;

/*************** STROKE ***************/
$stroke-bg-card: $neutral-200;
$stroke-bg-screen: $neutral-100;

$stroke-primary-default: $primary-500;
$stroke-primary-hover: $primary-400;
$stroke-primary-press: $primary-600;
$stroke-primary-selected: $primary-500;
$stroke-primary-subtle: $primary-200;

$stroke-neutral-subtle: $neutral-200;
$stroke-neutral-selected: $neutral-950;
$stroke-neutral-default: $neutral-950;
$stroke-neutral-medium: $neutral-400;
$stroke-neutral-press: $neutral-950;

$stroke-negative-default: $negative-500;

$stroke-positive-default: $positive-500;

/*************** DISPLAY ***************/
$display-neutral-light: $black-100;

/*************** FIXED ***************/
$fixed-inverse: $neutral-50;
