@import '../../../global/styles/config';

.create-password-page {
  @include flex-column;
  align-items: center;
  flex: 1;
  padding: 24px;

  &::before {
    @include before-img-background;

    background-image: url('../registration-page/images/top.webp'), url('../registration-page/images/bottom.webp');
    background-position: top -30px left -80px, right -300px top 400px;
    background-clip: border-box;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-image: url('../registration-page/images/top-lg.webp'),
        url('../registration-page/images/bottom-lg.webp');
      background-position: top left, right bottom;
    }
  }

  &__logo {
    align-self: center;
    padding: 16px 0 40px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }
}

.create-password,
.set-password-success {
  @include flex-column;
  flex: 1;
  gap: 10px;
  padding: 15px;
  width: 100%;
  background: $background-5;
  border: 1px solid $neutral-90;
  border-radius: 16px;
  padding: 16px;
  z-index: 1;
  background-color: rgba($color: $background-5, $alpha: 0.7);

  @media only screen and (min-width: $screen-md) {
    width: 460px;
    flex: unset;
    min-height: 500px;
  }

  > h1 {
    @include h4;
    text-align: center;
    margin-bottom: 20px;
  }

  .input_container {
    > input {
      height: 56px;
    }
  }

  > button,
  a {
    @include p-1-bold;
    margin-top: 10px;
    align-self: center;
    width: 200px;
    height: 40px;
  }

  > button {
    height: 56px;
  }
}

.set-password-success {
  > img {
    margin: 0 auto;
    width: 55px;
    height: 55px;
  }
}

.create-password {
  > h2 {
    @include p-1-bold;
    text-align: center;
  }

  > h3 {
    @include p-small-2;
    color: $red-60;
  }

  &__email {
    @include p-small-2;
    color: $opt1-60;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }
  }

  &__resend-otp {
    @include p-2;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
      @include flex-row;
      gap: 10px;
      align-items: center;
    }

    > button {
      @include p-2-bold;
      color: $primary-40;
      margin-top: 10px;
      @media (min-width: $screen-lg) {
        margin-top: 0;
      }
    }
  }

  &__back {
    @include p-small;
    margin-bottom: 25px;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }

    > button {
      @include p-small;
      color: $opt1-60;
      margin-left: 5px;
    }
  }
}
