@import '../../global/styles/config';
.statistics-container {
  @include flex-row;
  > hgroup {
    flex: 1;
    @include flex-column;
    align-items: center;

    @media (min-width: $screen-md) {
      width: 100px;
    }

    > h2 {
      @include p-small;

      color: $neutral-30;

      @media (min-width: $screen-xl) {
        @include p-2;
      }
    }

    > h3 {
      @include p-2-bold;

      @media (min-width: $screen-xl) {
        @include p-1-bold;
      }
    }
  }
}
