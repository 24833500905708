@import '../../global/styles/config';

.drawer {
  @include flex-column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  min-width: 300px;
  box-sizing: border-box;
  background-color: $background-3;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 100%;
  }

  &.open {
    transform: translateX(0);

    .drawer-overlay {
      display: block;
    }
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    padding: 24px;

    .drawer-close-button {
      background: none;
      border: none;
      font-size: 1.5em;
      cursor: pointer;
      color: white;
      transition: all 0.1s ease-out;

      &:hover {
        color: $primary-30;
        transform: scale(1.1);
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  .drawer-content {
    padding: 0 40px;
    overflow-y: auto;
    height: 100%;
  }
}

.drawer-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
