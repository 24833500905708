@import '../../../../global/styles/config';

.ad-surface-item {
  @include flex-column;
  gap: 16px;
  background: $background-4;
  padding: 20px;
  border-radius: $border-radius-sm;
  color: $neutral-white;

  &__title {
    @include flex-row;
    justify-content: space-between;

    .label {
      font-weight: $font-weight-bold;
    }
    > div {
      @include flex-row;
      & span:first-child {
        margin-right: 10px;
      }
    }
  }
  &__body {
    @include flex-column;

    @media only screen and (min-width: $screen-md) {
      > div {
        width: 80%;
      }
    }

    &_maxMin {
      @include flex-row;
      justify-content: space-between;
      gap: 20px;

      .input_container {
        flex: 1;
      }
    }

    &_placeholder {
      word-break: break-all;
      label {
        @include p-1-bold;
      }

      p {
        margin: 8px 0;
      }

      img,
      video {
        display: block;
        width: 80px;
        height: 80px;
        object-fit: cover;
        margin-top: 10px;
      }

      button {
        width: 100px;
        background: $primary-60;
        height: 32px;
      }
    }

    &_stats {
      margin-top: 16px;
      font-weight: $font-weight-bold;
      color: $neutral-20;

      .statistics-container {
        width: 100px;
        margin-top: 8px;
      }
    }
  }
}
