@import '../../../global/styles/config';

.login-page {
  @include flex-column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 25px;

  &::before {
    @include before-img-background;

    background-image: url('./images/top.webp'), url('./images/bottom.webp');
    background-position: top right -80px, left -200px bottom -350px;
    background-clip: border-box;

    @media (min-width: $screen-lg) {
      background-image: url('./images/top-lg.webp'), url('./images/bottom-lg.webp');
      background-position: top right, left bottom;
    }
  }

  &__logo {
    align-self: center;
    padding: 30px 0 40px;

    @media (min-width: $screen-lg) {
      padding: 55px 0 80px;
    }

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    align-self: center;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    background: rgba($color: $background-5, $alpha: 0.7);
    border-radius: 16px;
    border: 2px solid $primary-60;

    position: relative;

    @media (min-width: $screen-lg) {
      max-width: 520px;
    }

    > h1 {
      @include h4;
      text-align: center;
      margin-bottom: 30px;
      padding-bottom: 20px;
      border-bottom: 1px solid $neutral-90;
      @media (min-width: $screen-lg) {
        @include h2;
      }
    }

    .input_container {
      > input {
        height: 56px;
      }
    }

    > a {
      &:first-of-type {
        text-align: center;
        font-weight: $font-weight-bold;
      }
      @include p-2;
      display: block;
      width: 100%;
      color: $neutral-20;
      cursor: pointer;
      text-align: right;

      &:last-of-type {
        color: $opt1-60;
        margin: 20px 0;
        text-align: center;
      }
    }

    > button {
      @include p-1-bold;
      width: 200px;
      height: 56px;
      align-self: center;
      margin-top: 50px;
    }

    &_error {
      @include p-small-2;
      width: 100%;
      color: $red-60;
      text-align: center;
    }
    &_email {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      > p {
        @include p-small;
        margin-bottom: 25px;
        text-align: center;

        @media (min-width: $screen-lg) {
          text-align: center;
        }

        > button {
          @include p-small;
          color: $opt1-60;
          margin-left: 5px;
        }
      }
    }
  }
}
