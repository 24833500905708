@import '../../../global/styles/config';

.org-banner {
  @include flex-column;
  width: 100%;
  border-radius: 8px;
  position: relative;
  gap: 12px;
  padding: 16px;
  z-index: 1;
  overflow: hidden;

  &::before {
    @include before-img-background;
    background: radial-gradient(50% 50% at 50% 50%, #4414ab 0%, rgba(23, 20, 171, 0) 100%);
    width: 280px;
    height: 280px;
    top: -120px;
    left: -120px;
  }

  &::after {
    @include before-img-background;
    opacity: 0.4;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #4200ff 0%,
      rgba(84, 0, 255, 0.41) 41.15%,
      rgba(91, 0, 255, 0.19) 74.48%,
      rgba(97, 0, 255, 0) 100%
    );
    width: 280px;
    height: 280px;
    right: -120px;
    bottom: -120px;
    top: unset;
    left: unset;
  }

  &__header {
    @media (min-width: $screen-md) {
      @include flex-row;
      align-items: center;
      gap: 10px;
    }
    > h1 {
      @include h3;
      @include text-clamp(1);
    }

    > img {
      display: none;

      @media (min-width: $screen-md) {
        display: block;
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  &__stats {
    .statistics-container {
      > hgroup {
        align-items: flex-start;

        > h2 {
          margin-bottom: 5px;
        }
      }
    }
  }
}
