@import '../../../../global/styles/config';

.organization-game-settings {
  @include flex-column;
  background-color: rgba($color: $background-5, $alpha: 0.8);
  border-bottom-left-radius: $border-radius-md;
  border-bottom-right-radius: $border-radius-md;

  @media (min-width: $screen-md) {
    margin-top: 24px;
  }

  &__main {
    width: 100%;
    padding: 30px 0;
  }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 0;
    @include flex-row;

    &__aside {
      @include flex-column;
      > aside {
        flex: 1;
      }
    }

    &__main {
      width: 100%;
      padding: 30px 20px;
    }
  }

  @media only screen and (min-width: $screen-xl) {
    padding: 15px 25px;
  }
}
