@import '../../../global/styles/config';

.gamer-offers-page {
  @include flex-column;
  gap: 24px;
  position: relative;
  padding: 16px 0px;

  @media (max-width: $screen-md) {
    padding: 16px;
  }

  &_header {
    @include flex-row;
    align-items: flex-end;
    gap: 12px;

    > h3 {
      @include h3;
    }

    > span {
      @include h4;
      font-weight: 400;
      color: $neutral-40;
    }
  }
}
